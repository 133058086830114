

//images main photo
import imgMain_1 from "../../img/razvlechenie/gvardiya/razvlechenieMain.webp";
import imgMain_2 from "../../img/razvlechenie/scalodrom/ckalodromMain.webp";
import imgMain_3 from "../../img/razvlechenie/turClub/turClubMain.webp";
import imgMain_4 from "../../img/razvlechenie/avtosport/avtosportMain.webp";
import imgMain_5 from "../../img/razvlechenie/poleDance/poleDanceMain.webp";
import imgMain_6 from "../../img/razvlechenie/poleznayaPriv/poleznayaPrivMain.webp";
import imgMain_7 from "../../img/razvlechenie/rusich/rusichMain.webp";


//pentbol gvardiya
import gvardiya_1 from "../../img/razvlechenie/gvardiya/razvlechenieSwiper1.webp";
import gvardiya_2 from "../../img/razvlechenie/gvardiya/razvlechenieSwiper2.webp";
import gvardiya_3 from "../../img/razvlechenie/gvardiya/razvlechenieSwiper3.webp";
import gvardiya_4 from "../../img/razvlechenie/gvardiya/razvlechenieSwiper4.webp";
import gvardiya_5 from "../../img/razvlechenie/gvardiya/razvlechenieSwiper5.webp";
//scalodrom
import scalodrom_1 from "../../img/razvlechenie/scalodrom/scalodromSwiper1.webp";
import scalodrom_2 from "../../img/razvlechenie/scalodrom/scalodromSwiper2.webp";
import scalodrom_3 from "../../img/razvlechenie/scalodrom/scalodromSwiper3.webp";
import scalodrom_4 from "../../img/razvlechenie/scalodrom/scalodromSwiper4.webp";
import scalodrom_5 from "../../img/razvlechenie/scalodrom/scalodromSwiper5.webp";
//turClub
import turClub_1 from "../../img/razvlechenie/turClub/turClubSwiper.webp";
import turClub_2 from "../../img/razvlechenie/turClub/turClubSwiper2.webp";
import turClub_3 from "../../img/razvlechenie/turClub/turClubSwiper3.webp";
import turClub_4 from "../../img/razvlechenie/turClub/turClubSwiper4.webp";
import turClub_5 from "../../img/razvlechenie/turClub/turClubSwiper5.webp";
//avtosport
import avtosport_1 from "../../img/razvlechenie/avtosport/avtosportSwiper1.webp";
import avtosport_2 from "../../img/razvlechenie/avtosport/avtosportSwiper2.webp";
import avtosport_3 from "../../img/razvlechenie/avtosport/avtosportSwiper3.webp";
import avtosport_4 from "../../img/razvlechenie/avtosport/avtosportSwiper4.webp";
import avtosport_5 from "../../img/razvlechenie/avtosport/avtosportSwiper5.webp";
//poleDance
import poleDance_1 from "../../img/razvlechenie/poleDance/poleDanceSwiper1.webp";
import poleDance_2 from "../../img/razvlechenie/poleDance/poleDanceSwiper2.webp";
import poleDance_3 from "../../img/razvlechenie/poleDance/poleDanceSwiper3.webp";
import poleDance_4 from "../../img/razvlechenie/poleDance/poleDanceSwiper4.webp";
import poleDance_5 from "../../img/razvlechenie/poleDance/poleDanceSwiper5.webp";
//poleznayaPriv
import poleznayaPriv_1 from "../../img/razvlechenie/poleznayaPriv/poleznayaPrivSwiper_1.webp";
import poleznayaPriv_2 from "../../img/razvlechenie/poleznayaPriv/poleznayaPrivSwiper_2.webp";
import poleznayaPriv_3 from "../../img/razvlechenie/poleznayaPriv/poleznayaPrivSwiper_3.webp";
import poleznayaPriv_4 from "../../img/razvlechenie/poleznayaPriv/poleznayaPrivSwiper_4.webp";
import poleznayaPriv_5 from "../../img/razvlechenie/poleznayaPriv/poleznayaPrivSwiper_5.webp";
//rusich
import rusich_1 from "../../img/razvlechenie/rusich/rusichSwiper.webp";
import rusich_2 from "../../img/razvlechenie/rusich/rusichSwiper2.webp";
import rusich_3 from "../../img/razvlechenie/rusich/rusichSwiper3.webp";
import rusich_4 from "../../img/razvlechenie/rusich/rusichSwiper4.webp";
import rusich_5 from "../../img/razvlechenie/rusich/rusichSwiper5.webp";



export const razvlecheniePage = [
  {
    id: "1",
    //заставка объявления
    img: imgMain_1,
    //название объявления
    name: "«ГВАРДИЯ» Пейнтбол, Лазертаг",
    webLink: "https://vk.com/uslugi-42642250",
    vkLink: "https://vk.com/lasertag2d",
    // Главный текст на subPage странице
    title:
      "Центр активного отдыха. Приходите всей семьей! Именинники получают вход БЕСПЛАТНО",
    // последующий текст на subPage странице
    subtitle:
      "Все, кто приходит на полигон автоматически считается Помощником, Игроком или Гостем\nИГРОК - участник игры, получивший костюм и снаряжение, оплачивает полную стоимость игры\nПОМОЩНИК (Помощник Инструктора) - родители и старшие родственники (18+), не принимающие участия в игре. Пришедшие помочь в проведении игры (до 3 взрослых). Помощники могут бесплатно помогать в проведении игры, накрывать на стол, фотографировать и не платить за нахождение на полигоне. Если родителей более 3-х и/или они хотят отдохнуть и отпраздновать за столом, то они становятся ГОСТЯМИ\nГОСТИ - не играющие и не помогающие в проведении игры взрослые, а также дети (в том числе не играющие дети до 3-х лет). Оплата за гостей рассчитывается как 50% стоимости оплаты за одного игрока\nОрганизаторы оставляют за собой право делать пересечение (два заказа в одно время) игр (при малочисленных составов)",
    //место проведения
    place: "Лессной массив 'горка'",
    // ссылка
    placeLink: "https://yandex.ru/maps/-/CDRs4Qp-",
    //адрес
    adress: "ул. III Интернационала 96А",
    //вид представления
    performance: "",
    //возростные ограничения
    age: "+12",
    //цена входа
    price: "от 200 руб.",
    //дата публикации
    data: "4 апреля 2024",
    img_1: gvardiya_1,
    img_2: gvardiya_2,
    img_3: gvardiya_3,
    img_4: gvardiya_4,
    img_5: gvardiya_5,
  },
  {
    id: "2",
    //заставка объявления
    img: imgMain_2,
    //название объявления
    name: "Скалодром «Вершина»",
    webLink: "https://vk.com/@climb173-prais",
    vkLink: "https://vk.com/climb173",
    // Главный текст на subPage странице
    title:
      "Мы проводим тренировки пока есть желающие тренироваться, готовимся к настоящим скалам и горам. Покори свою Вершину!",
    // последующий текст на subPage странице
    subtitle:
      "Для любителей полазить по горным склонам необходимо не только специальное снаряжение, но и знание некоторых приемов скалолазания. Простейшие правила, которые необходимы для развития навыков, это правильность постановки ноги, варианты зацепов за скалы, хватов и тому подобное. Все они важны для того, чтобы обучиться правильно и безопасно лазать по горам и сказал. Поэтому важно вначале рассмотреть все эти правила, которые как для новичков, так и для опытных скалолазов должны быть заповедями.",
    //место проведения
    place: "ГиперМагнит, 2 этаж.",
    // ссылка
    placeLink: "https://yandex.ru/maps/-/CDRsaQLx",
    //адрес
    adress: "ул. Юнг Северного Флота, 8",
    //вид представления
    performance: "",
    //возростные ограничения
    age: "+12",
    //цена входа
    price: "от 200 руб.",
    //дата публикации
    data: "4 апреля 2024",
    img_1: scalodrom_1,
    img_2: scalodrom_2,
    img_3: scalodrom_3,
    img_4: scalodrom_4,
    img_5: scalodrom_5,
  },
  {
    id: "3",
    //заставка объявления
    img: imgMain_3,
    //название объявления
    name: "Турклуб «Горизонт»",
    webLink: "https://vk.com/@climb173-prais",
    vkLink: "https://vk.com/gorizont_dd",
    // Главный текст на subPage странице
    title:
      "Турклуб проводит интересные и запоминающиеся туры, эмоции от которых останутся на всю жизнь",
    // последующий текст на subPage странице
    subtitle:
      "Предлагаем ознакомится с тем, что Турклуб «Горизонт» пишет о своих турах: «Отправляйтесь в увлекательные приключения с нашими запоминающимися турами! Мы создаем не просто поездки, а незабываемые эмоции, которые останутся с вами на всю жизнь. Погрузитесь в уникальные места, окунитесь в местную культуру, и откройте для себя мир новых впечатлений вместе с нами. Наши туры - это не просто путешествия, а истории, которые вы будете рассказывать друзьям и вспоминать с улыбкой. Подарите себе незабываемые моменты с нами!»",
    //место проведения
    place: "",
    // ссылка
    placeLink: "",
    //адрес
    adress: "-",
    //вид представления
    performance: "-",
    //возростные ограничения
    age: "+12",
    //цена входа
    price: "от 400 руб.",
    //дата публикации
    data: "4 апреля 2024",
    img_1: turClub_1,
    img_2: turClub_2,
    img_3: turClub_3,
    img_4: turClub_4,
    img_5: turClub_5,
  },
  {
    id: "4",
    //заставка объявления
    img: imgMain_4,
    //название объявления
    name: "Автоспорт",
    webLink: "https://t.me/avtosport73",
    vkLink: "https://vk.com/avtosport173?from=search",
    // Главный текст на subPage странице
    title: "Сообщество автолюбителей г. Димитровграда.",
    // последующий текст на subPage странице
    subtitle:
      "В кругах сообщества титулованные профессионалы, начинающие пилоты и рядовые автолюбители всех возрастов, объединенные общей целью - возродить, популяризировать и развить автоспорт в нашем городе./nЕсли у вас есть интерес к автомобилям и всему что с ними связанно, вы хотите попробовать себя пилотом, присоединяйтесь к сообществу!/nБез возрастных ограничений!\n\nДаже если у вас нет авто или ВУ, участники сообщества помогут ощутить драйв скорости и управляемого заноса на своих автомобилях!\n\nТут: строят машины, учатся экстремальному вождению, обмениваются опытом, круто проводят свободное время",
    //место проведения
    place: "-",
    // ссылка
    placeLink: "",
    //адрес
    adress: "-",
    //вид представления
    performance: "",
    //возростные ограничения
    age: "Без ограничений",
    //цена входа
    price: "-",
    //дата публикации
    data: "4 апреля 2024",
    img_1: avtosport_1,
    img_2: avtosport_2,
    img_3: avtosport_3,
    img_4: avtosport_4,
    img_5: avtosport_5,
  },
  {
    id: "5",
    //заставка объявления
    img: imgMain_5,
    //название объявления
    name: "PoleFit Studio",
    vkLink: "https://vk.com/polefitsudiodd",
    // Главный текст на subPage странице
    title:
      "Наши профессиональные тренеры помогут вам достичь поставленных целей, улучшить физическую форму и развить гибкость. Присоединяйтесь к нам и ощутите новые возможности вашего тела!",
    // последующий текст на subPage странице
    subtitle:
      "Pole Sport - развивает силу, выносливость и координацию. Это не только эффективные тренировки, но и захватывающее спортивное искусство.\nPole Exot - гармоничное сочетание танцевальных движений и акробатики на пилоне. Помогает развить пластичность, грацию и выразительность.\nСтретчинг - увеличивает гибкость, снимает мышечное напряжение и улучшает общее самочувствие. Идеально подходит для любого уровня подготовки.\nСтрип - сочетание чувственных движений и танцевальной техники. Поднимает самооценку и уверенность в себе, помогает раскрыть женственность.\n\nНаши профессиональные тренеры имеют многолетний опыт и индивидуальный подход к каждому клиенту. Мы предлагаем удобное расписание и уютную атмосферу для тренировок. Присоединяйтесь к нам и достигайте новых высот в физической форме и уверенности!",
    //место проведения
    place: "Академ городок",
    // ссылка
    placeLink: "https://yandex.ru/maps/-/CDRwfXyj",
    //адрес
    adress: "улица Менделеева, 3, Димитровград",
    //вид представления
    performance: "Танцевальное сообщество",
    //возростные ограничения
    age: "+6",
    //цена входа
    price: "от 300 руб.",
    //дата публикации
    data: "4 апреля 2024",
    img_1: poleDance_1,
    img_2: poleDance_2,
    img_3: poleDance_3,
    img_4: poleDance_4,
    img_5: poleDance_5,
  },
  {
    id: "6",
    //заставка объявления
    img: imgMain_6,
    //название объявления
    name: "ПРОКАТ «Полезная привычка»",
    vkLink: "https://vk.com/poleznayprivichka",
    // Главный текст на subPage странице
    title:
      "По мнению компании, ценность здорового образа жизни признается, и них вас открыт прокат спортивного инвентаря по доступным ценам!",
    // последующий текст на subPage странице
    subtitle:
      "Поддерживать активный образ жизни без лишних затрат стало проще благодаря услугам проката. В аренду предлагается широкий выбор спортивного оборудования: велосипеды, ролики, лыжи и бадминтонные комплекты. Начните заниматься спортом с удовольствием прямо сейчас!",
    //место проведения
    place: "Напротив стадиона «Старт»",
    // ссылка
    placeLink: "https://yandex.ru/maps/-/CDR~4N-y",
    //адрес
    adress: "пр. Ленина д.9",
    //вид представления
    performance: "Здоровый образ жизни",
    //возростные ограничения
    age: "+6",
    //цена входа
    price: "от 200 руб.",
    //дата публикации
    data: "4 апреля 2024",
    img_1: poleznayaPriv_1,
    img_2: poleznayaPriv_2,
    img_3: poleznayaPriv_3,
    img_4: poleznayaPriv_4,
    img_5: poleznayaPriv_5,
  },
  {
    id: "7",
    //заставка объявления
    img: imgMain_7,
    //название объявления
    name: "Водная база отдыха «Русич»",
    webLink: "https://rusich-dd.ru/",
    vkLink: "https://vk.com/rusichdd",
    // Главный текст на subPage странице
    title:
      "Предлагаем комфортабельные Банкетные залы для проведения юбилеев, дней рождений, свадеб, корпоративов.",
    // последующий текст на subPage странице
    subtitle:
      "Кафе, мангальная, детская площадка, прокат велосипедов, чистый пляж, дискотеки, VIP домики для мероприятий, все это и многое другое на Водной базе отдыха «Русич» \n VIP домики на любую компанию. правильный отдых в гармонии с природой.",
    //место проведения
    place: "Река черемшан",
    // ссылка
    placeLink: "https://yandex.ru/maps/-/CDR~7A4n",
    //адрес
    adress: "Дрогобычская ул., 18Б",
    //вид представления
    performance: "",
    //возростные ограничения
    age: "+12",
    //цена входа
    price: "от 200 руб.",
    //дата публикации
    data: "4 апреля 2024",
    img_1: rusich_1,
    img_2: rusich_2,
    img_3: rusich_3,
    img_4: rusich_4,
    img_5: rusich_5,
  },
];
export default razvlecheniePage;
