// bibliotek
import { Route, Routes } from "react-router-dom";
import React, { lazy, Suspense } from "react";


// componets
import Header from "./componets/header/Header.jsx";
import Footer from "./componets/footer/Footer.jsx";
import Loader from "./componets/loader/Loader.jsx";
import NoMatch from "./componets/noMatch/NoMatch.jsx";


//razvlechenie
import SubPagerazvlechenie1 from "./subPages/razvlechenie/SubPagerazvlechenie1.jsx";
import SubPagerazvlechenie2 from "./subPages/razvlechenie/SubPagerazvlechenie2.jsx";
import SubPagerazvlechenie3 from "./subPages/razvlechenie/SubPagerazvlechenie3.jsx";
import SubPagerazvlechenie4 from "./subPages/razvlechenie/SubPagerazvlechenie4.jsx";
import SubPagerazvlechenie5 from "./subPages/razvlechenie/SubPagerazvlechenie5.jsx";
import SubPagerazvlechenie6 from "./subPages/razvlechenie/SubPagerazvlechenie6.jsx";
import SubPagerazvlechenie7 from "./subPages/razvlechenie/SubPagerazvlechenie7.jsx";
//obrazovanie
import SubPageObrazovanie1 from "./subPages/obrazovanie/SubPageObrazovanie1.jsx";
import SubPageObrazovanie2 from "./subPages/obrazovanie/SubPageObrazovanie2.jsx";
import SubPageObrazovanie3 from "./subPages/obrazovanie/SubPageObrazovanie3.jsx";
import SubPageObrazovanie4 from "./subPages/obrazovanie/SubPageObrazovanie4.jsx";
import SubPageObrazovanie5 from "./subPages/obrazovanie/SubPageObrazovanie5.jsx";
import SubPageObrazovanie6 from "./subPages/obrazovanie/SubPageObrazovanie6.jsx";
//eda
import SubPageEda1 from "./subPages/eda/SubPageEda1.jsx";
import SubPageEda2 from "./subPages/eda/SubPageEda2.jsx";
import SubPageEda3 from "./subPages/eda/SubPageEda3.jsx";
import SubPageEda4 from "./subPages/eda/SubPageEda4.jsx";
import SubPageEda5 from "./subPages/eda/SubPageEda5.jsx";
import SubPageEda6 from "./subPages/eda/SubPageEda6.jsx";
//Vzroslie
import SubPageVzroslie1 from "./subPages/vzroslie/SubPageVzroslie1.jsx";
import SubPageVzroslie2 from "./subPages/vzroslie/SubPageVzroslie2.jsx";
import SubPageVzroslie3 from "./subPages/vzroslie/SubPageVzroslie3.jsx";
import SubPageVzroslie4 from "./subPages/vzroslie/SubPageVzroslie4.jsx";
import SubPageVzroslie5 from "./subPages/vzroslie/SubPageVzroslie5.jsx";
import SubPageVzroslie6 from "./subPages/vzroslie/SubPageVzroslie6.jsx";
//zagorodom
import SubPageZagarodom1 from "./subPages/zagorodom/SubPageZagarodom1.jsx";
import SubPageZagarodom2 from "./subPages/zagorodom/SubPageZagarodom2.jsx";
import SubPageZagarodom3 from "./subPages/zagorodom/SubPageZagarodom3.jsx";
import SubPageZagarodom4 from "./subPages/zagorodom/SubPageZagarodom4.jsx";
import SubPageZagarodom5 from "./subPages/zagorodom/SubPageZagarodom5.jsx";
import SubPageZagarodom6 from "./subPages/zagorodom/SubPageZagarodom6.jsx";
//deti
import SubPageDeti1 from "./subPages/deti/SubPageDeti1.jsx";
import SubPageDeti2 from "./subPages/deti/SubPageDeti2.jsx";
import SubPageDeti3 from "./subPages/deti/SubPageDeti3.jsx";
import SubPageDeti4 from "./subPages/deti/SubPageDeti4.jsx";
import SubPageDeti5 from "./subPages/deti/SubPageDeti5.jsx";
import SubPageDeti6 from "./subPages/deti/SubPageDeti6.jsx";
//zdorovie
import SubPageZdorovie1 from "./subPages/zdorovie/SubPageZdorovie1.jsx";
import SubPageZdorovie2 from "./subPages/zdorovie/SubPageZdorovie2.jsx";
import SubPageZdorovie3 from "./subPages/zdorovie/SubPageZdorovie3.jsx";
import SubPageZdorovie4 from "./subPages/zdorovie/SubPageZdorovie4.jsx";
import SubPageZdorovie5 from "./subPages/zdorovie/SubPageZdorovie5.jsx";
import SubPageZdorovie6 from "./subPages/zdorovie/SubPageZdorovie6.jsx";
//kultura
import SubPageKultura1 from "./subPages/kultura/SubPageKulture1.jsx";
import SubPageKultura2 from "./subPages/kultura/SubPageKulture2.jsx";
import SubPageKultura3 from "./subPages/kultura/SubPageKulture3.jsx";
import SubPageKultura4 from "./subPages/kultura/SubPageKulture4.jsx";
import SubPageKultura5 from "./subPages/kultura/SubPageKulture5.jsx";
import SubPageKultura6 from "./subPages/kultura/SubPageKulture6.jsx";
import SubPageKultura7 from "./subPages/kultura/SubPageKulture7.jsx";
//kino
import SubPageKino1 from "./subPages/kino/SubPageKino1.jsx";
import SubPageKino2 from "./subPages/kino/SubPageKino2.jsx";
import SubPageKino3 from "./subPages/kino/SubPageKino3.jsx";
import SubPageKino4 from "./subPages/kino/SubPageKino4.jsx";
import SubPageKino5 from "./subPages/kino/SubPageKino5.jsx";
import SubPageKino6 from "./subPages/kino/SubPageKino6.jsx";
//novosty
import SubPageNovosty1 from "./subPages/novosty/SubPageNovosty1.jsx";
import SubPageNovosty2 from "./subPages/novosty/SubPageNovosty2.jsx";
import SubPageNovosty3 from "./subPages/novosty/SubPageNovosty3.jsx";
import SubPageNovosty4 from "./subPages/novosty/SubPageNovosty4.jsx";
import SubPageNovosty5 from "./subPages/novosty/SubPageNovosty5.jsx";
import SubPageNovosty6 from "./subPages/novosty/SubPageNovosty6.jsx";
// style
import "./style.scss";
import "./pages/commonStyle.scss";
// pages
const Home = lazy(() => import("./pages/home/Home.jsx"));
const Kino = lazy(() => import("./pages/kino/Kino.jsx"));
const Kultura = lazy(() => import("./pages/kultura/Kultura.jsx"));
const Children = lazy(() => import("./pages/children/Children.jsx"));
const Vzroslie = lazy(() => import("./pages/vzroslie/Vzroslie.jsx"));
const Education = lazy(() => import("./pages/education/Education.jsx"));
const Food = lazy(() => import("./pages/food/Food.jsx"));
const Nature = lazy(() => import("./pages/nature/Nature.jsx"));
const News = lazy(() => import("./pages/news/News.jsx"));
const Razvlechenie = lazy(() => import("./pages/razvlechenie/Razvlechenie.jsx"));
const Zdorovie = lazy(() => import("./pages/zdorovie/Zdorovie.jsx"));
const Summer = lazy(() => import("./pages/summer/Summer.jsx"));


function App() {
  return (
    <div className="wrapper">
      <header>
        <Header />
      </header>
      <main>
        <Routes>
          <Route
            path="/"
            exact
            index
            element={
              <Suspense fallback={<Loader />}>
                <Home />
              </Suspense>
            }
          />
          <Route
            path="/kino"
            element={
              <Suspense fallback={<Loader />}>
                <Kino />
              </Suspense>
            }
          />
          <Route
            path="/kultura"
            element={
              <Suspense fallback={<Loader />}>
                <Kultura />
              </Suspense>
            }
          />
          <Route
            path="/children"
            element={
              <Suspense fallback={<Loader />}>
                <Children />
              </Suspense>
            }
          />
          <Route
            path="/vzroslie"
            element={
              <Suspense fallback={<Loader />}>
                <Vzroslie />
              </Suspense>
            }
          />
          <Route
            path="/education"
            element={
              <Suspense fallback={<Loader />}>
                <Education />
              </Suspense>
            }
          />
          <Route
            path="/food"
            element={
              <Suspense fallback={<Loader />}>
                <Food />
              </Suspense>
            }
          />
          <Route
            path="/nature"
            element={
              <Suspense fallback={<Loader />}>
                <Nature />
              </Suspense>
            }
          />
          <Route
            path="/news"
            element={
              <Suspense fallback={<Loader />}>
                <News />
              </Suspense>
            }
          />
          <Route
            path="/razvlechenie"
            element={
              <Suspense fallback={<Loader />}>
                <Razvlechenie />
              </Suspense>
            }
          />
          <Route
            path="/zdorovie"
            element={
              <Suspense fallback={<Loader />}>
                <Zdorovie />
              </Suspense>
            }
          />
          <Route
            path="/summer"
            element={
              <Suspense fallback={<Loader />}>
                <Summer />
              </Suspense>
            }
          />

          {/* subpages */}
          <Route
            path="/razvlecheniePage_1"
            element={<SubPagerazvlechenie1 />}
          />
          <Route
            path="/razvlecheniePage_2"
            element={<SubPagerazvlechenie2 />}
          />
          <Route
            path="/razvlecheniePage_3"
            element={<SubPagerazvlechenie3 />}
          />
          <Route
            path="/razvlecheniePage_4"
            element={<SubPagerazvlechenie4 />}
          />
          <Route
            path="/razvlecheniePage_5"
            element={<SubPagerazvlechenie5 />}
          />
          <Route
            path="/razvlecheniePage_6"
            element={<SubPagerazvlechenie6 />}
          />
          <Route
            path="/razvlecheniePage_7"
            element={<SubPagerazvlechenie7 />}
          />

          <Route path="/obrazovaniePage_1" element={<SubPageObrazovanie1 />} />
          <Route path="/obrazovaniePage_2" element={<SubPageObrazovanie2 />} />
          <Route path="/obrazovaniePage_3" element={<SubPageObrazovanie3 />} />
          <Route path="/obrazovaniePage_4" element={<SubPageObrazovanie4 />} />
          <Route path="/obrazovaniePage_5" element={<SubPageObrazovanie5 />} />
          <Route path="/obrazovaniePage_6" element={<SubPageObrazovanie6 />} />

          <Route path="/edaPage_1" element={<SubPageEda1 />} />
          <Route path="/edaPage_2" element={<SubPageEda2 />} />
          <Route path="/edaPage_3" element={<SubPageEda3 />} />
          <Route path="/edaPage_4" element={<SubPageEda4 />} />
          <Route path="/edaPage_5" element={<SubPageEda5 />} />
          <Route path="/edaPage_6" element={<SubPageEda6 />} />

          <Route path="/vzrosliePage_1" element={<SubPageVzroslie1 />} />
          <Route path="/vzrosliePage_2" element={<SubPageVzroslie2 />} />
          <Route path="/vzrosliePage_3" element={<SubPageVzroslie3 />} />
          <Route path="/vzrosliePage_4" element={<SubPageVzroslie4 />} />
          <Route path="/vzrosliePage_5" element={<SubPageVzroslie5 />} />
          <Route path="/vzrosliePage_6" element={<SubPageVzroslie6 />} />

          <Route path="/zagorodomPage_1" element={<SubPageZagarodom1 />} />
          <Route path="/zagorodomPage_2" element={<SubPageZagarodom2 />} />
          <Route path="/zagorodomPage_3" element={<SubPageZagarodom3 />} />
          <Route path="/zagorodomPage_4" element={<SubPageZagarodom4 />} />
          <Route path="/zagorodomPage_5" element={<SubPageZagarodom5 />} />
          <Route path="/zagorodomPage_6" element={<SubPageZagarodom6 />} />

          <Route path="/detiPage_1" element={<SubPageDeti1 />} />
          <Route path="/detiPage_2" element={<SubPageDeti2 />} />
          <Route path="/detiPage_3" element={<SubPageDeti3 />} />
          <Route path="/detiPage_4" element={<SubPageDeti4 />} />
          <Route path="/detiPage_5" element={<SubPageDeti5 />} />
          <Route path="/detiPage_6" element={<SubPageDeti6 />} />

          <Route path="/zdoroviePage_1" element={<SubPageZdorovie1 />} />
          <Route path="/zdoroviePage_2" element={<SubPageZdorovie2 />} />
          <Route path="/zdoroviePage_3" element={<SubPageZdorovie3 />} />
          <Route path="/zdoroviePage_4" element={<SubPageZdorovie4 />} />
          <Route path="/zdoroviePage_5" element={<SubPageZdorovie5 />} />
          <Route path="/zdoroviePage_6" element={<SubPageZdorovie6 />} />

          <Route path="/kulturaPage_1" element={<SubPageKultura1 />} />
          <Route path="/kulturaPage_2" element={<SubPageKultura2 />} />
          <Route path="/kulturaPage_3" element={<SubPageKultura3 />} />
          <Route path="/kulturaPage_4" element={<SubPageKultura4 />} />
          <Route path="/kulturaPage_5" element={<SubPageKultura5 />} />
          <Route path="/kulturaPage_6" element={<SubPageKultura6 />} />
          <Route path="/kulturaPage_7" element={<SubPageKultura7 />} />

          <Route path="/kinoPage_1" element={<SubPageKino1 />} />
          <Route path="/kinoPage_2" element={<SubPageKino2 />} />
          <Route path="/kinoPage_3" element={<SubPageKino3 />} />
          <Route path="/kinoPage_4" element={<SubPageKino4 />} />
          <Route path="/kinoPage_5" element={<SubPageKino5 />} />
          <Route path="/kinoPage_6" element={<SubPageKino6 />} />

          <Route path="/novostyPage_1" element={<SubPageNovosty1 />} />
          <Route path="/novostyPage_2" element={<SubPageNovosty2 />} />
          <Route path="/novostyPage_3" element={<SubPageNovosty3 />} />
          <Route path="/novostyPage_4" element={<SubPageNovosty4 />} />
          <Route path="/novostyPage_5" element={<SubPageNovosty5 />} />
          <Route path="/novostyPage_6" element={<SubPageNovosty6 />} />

          {/* not page 404 */}
          <Route path="*" element={<NoMatch />} />
        </Routes>
      </main>
      <footer>
        <Footer />
      </footer>
    </div>
  );
}

export default App;
