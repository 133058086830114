

import SwiperSubPage from "../swiperSubPage/swiperSubPage.jsx";

//obj
import {razvlecheniePage} from "../../asset/obj/razclechenie/razvlecheniePage.js"; //вся информация о бизнесе
//images
// import iconWebsite from "../../asset/img/icon/iconWebsite.svg";
import iconVk from "../../asset/img//icon/icons-vk.svg";

//style
import "../subPages.scss";
const objRazvlechenie = razvlecheniePage[5];
export default function SubPagerazvlechenie6(){
    return (
      <div className="subItem__wrapper _container">
        <div className="subItem__images">
          <img src={objRazvlechenie.img} alt="" />
          <h3>{objRazvlechenie.name}</h3>
        </div>
        <div className="subItem__actions">
          <a
            className="button__buy"
            target="_blank"
            rel="noreferrer"
            href="tel:+79170536450"
          >
            Позвонить
          </a>
          <div className="subItem__actions__link">
            <a target="_blank" rel="noreferrer" href={objRazvlechenie.vkLink}>
              <img src={iconVk} alt="" />
              <strong>Вконтакте</strong>
            </a>
          </div>
        </div>
        <div className="subItem__wrapper__container">
          <div className="subItem__info">
            <h2>{objRazvlechenie.name}</h2>
            <h3>{objRazvlechenie.title}</h3>
            <p>{objRazvlechenie.subtitle}</p>
          </div>
          <div className="subItem__swiper">
            <SwiperSubPage props={razvlecheniePage[5]} />
          </div>
          <div className="subItem__contact">
            <ul className="subItem__contact__info">
              <li className="subItem__contact__info__place">
                <strong>МЕСТО ПРОВЕДЕНИЯ</strong>
                {objRazvlechenie.place}
              </li>
              <li className="subItem__contact__info__adress">
                <strong>АДРЕС</strong>
                <a
                  href={objRazvlechenie.placeLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {objRazvlechenie.adress}
                </a>
              </li>
              <li className="subItem__contact__info__performance">
                <strong>ВИД РАЗВЛЕЧЕНИЯ</strong>
                {objRazvlechenie.performance}
              </li>
              <li className="subItem__contact__info__age">
                <strong>ВОЗРАСТНОЕ ОГРАНИЧЕНИЕ</strong>
                {objRazvlechenie.age}
              </li>
              <li className="subItem__contact__info__price">
                <strong>ЦЕНА</strong>
                {objRazvlechenie.price}
              </li>
            </ul>
            <div className="subItem__contact__schedule">
              <h4>Расписание</h4>
              <div className="subItem__contact__schedule__info">
                {/* <h5>26 декабря 2023 – 31 марта 2024</h5> */}
                <h6>c 9:00 до 17:00 ежедневно</h6>
              </div>
            </div>
            <div className="subItem__contact__dataPubloscher">
              Опубликовано {objRazvlechenie.data}
            </div>
          </div>
        </div>
      </div>
    );
}
