

//images main photo
import imgMain_1 from "../../img/eda/diner/edaMain.webp";
import imgMain_2 from "../../img/eda/papamama/XXXL.webp";
import imgMain_3 from "../../img/eda/bruklin/bruklinMain.webp";
import imgMain_4 from "../../img/eda/kalyary/kaliaryMain2.webp";
import imgMain_5 from "../../img/eda/pizzaevskiy/pizzaevskiyMain.webp";
import imgMain_6 from "../../img/eda/coffegreen/cofegreenMain.webp";
import imgMain_7 from "../../img/eda/seiko/seikoMain.webp";


//diner
import diner_1 from "../../img/eda/diner/edaSwiper.webp";
import diner_2 from "../../img/eda/diner/edaSwiper2.webp";
import diner_3 from "../../img/eda/diner/edaSwiper3.webp";
import diner_4 from "../../img/eda/diner/edaSwiper4.webp";
import diner_5 from "../../img/eda/diner/edaSwiper5.webp";
//papaMama
import papaMama_1 from "../../img/eda/papamama/swiperpage1.webp";
import papaMama_2 from "../../img/eda/papamama/swiperpage2.webp";
import papaMama_3 from "../../img/eda/papamama/swiperpage3.webp";
import papaMama_4 from "../../img/eda/papamama/swiperpage4.webp";
import papaMama_5 from "../../img/eda/papamama/swiperpage5.webp";
//bruklin
import bruklinSwiper_1 from "../../img/eda/bruklin/bruklinswiper1.webp";
import bruklinSwiper_2 from "../../img/eda/bruklin/bruklinswiper2.webp";
import bruklinSwiper_3 from "../../img/eda/bruklin/bruklinswiper3.webp";
import bruklinSwiper_4 from "../../img/eda/bruklin/bruklinswiper4.webp";
import bruklinSwiper_5 from "../../img/eda/bruklin/bruklinswiper5.webp";
//kaliary
import kaliary_1 from "../../img/eda/kalyary/kaliarySwiper1.webp";
import kaliary_2 from "../../img/eda/kalyary/kaliarySwiper2.jpg";
import kaliary_3 from "../../img/eda/kalyary/kaliarySwiper3.webp";
import kaliary_4 from "../../img/eda/kalyary/kaliarySwiper4.webp";
import kaliary_5 from "../../img/eda/kalyary/kaliarySwiper5.jpg";
//pizzaevskiy
import pizzaevskiy_1 from "../../img/eda/pizzaevskiy/pizzaevskiySwiper.webp";
import pizzaevskiy_2 from "../../img/eda/pizzaevskiy/pizzaevskiySwiper2.webp";
import pizzaevskiy_3 from "../../img/eda/pizzaevskiy/pizzaevskiySwiper3.webp";
import pizzaevskiy_4 from "../../img/eda/pizzaevskiy/pizzaevskiySwiper4.webp";
import pizzaevskiy_5 from "../../img/eda/pizzaevskiy/pizzaevskiySwiper5.webp";
//coffegreen
import coffegreen_1 from "../../img/eda/coffegreen/cofegreenSwiper1.webp";
import coffegreen_2 from "../../img/eda/coffegreen/cofegreenSwiper2.webp";
import coffegreen_3 from "../../img/eda/coffegreen/cofegreenSwiper3.webp";
import coffegreen_4 from "../../img/eda/coffegreen/cofegreenSwiper4.webp";
import coffegreen_5 from "../../img/eda/coffegreen/cofegreenSwiper5.webp";
//seiko
import seiko_1 from "../../img/eda/seiko/seikoSwiper.webp";
import seiko_2 from "../../img/eda/seiko/seikoSwiper2.webp";
import seiko_3 from "../../img/eda/seiko/seikoSwiper3.webp";
import seiko_4 from "../../img/eda/seiko/seikoSwiper4.webp";
import seiko_5 from "../../img/eda/seiko/seikoSwiper5.webp";



export const edaPage = [
  {
    id: "1",
    //заставка объявления
    img: imgMain_1,
    //название объявления
    name: "The Diner",
    vkLink: "https://vk.com/thediner_dd",
    // Главный текст на subPage странице
    title:
      "Кафе The Diner — это место, где можно провести время с друзьями или семьей.",
    // последующий текст на subPage странице
    subtitle:
      "Здесь подают вкусные блюда, такие как гамбургеры, картофель и салаты, а также разнообразные напитки, включая молочные коктейли и пиво.\n Интерьер кафе выполнен в стиле классического американского ресторана с яркими постерами и неоновой вывеской. Кроме того, в кафе есть детский уголок и детское кресло.",
    //место проведения
    place: "Дом торговли",
    // ссылка
    placeLink: "https://yandex.ru/maps/-/CDVA4F6K",
    //адрес
    adress: "Гвардейская ул., 21",
    //вид представления
    performance: "",
    //возростные ограничения
    age: "+12",
    //цена входа
    price: "от 200 руб.",
    //дата публикации
    data: "4 апреля 2024",
    img_1: diner_1,
    img_2: diner_2,
    img_3: diner_3,
    img_4: diner_4,
    img_5: diner_5,
  },
  {
    id: "2",
    //заставка объявления
    img: imgMain_2,
    //название объявления
    name: "ПапаМамаКофе",
    // Главный текст на subPage странице
    title:
      "Кофейня «ПапаМамаКофе» — это уютное и стильное место, где можно насладиться ароматным кофе и разнообразными десертами, такими как сырники и творожные ватрушки.",
    // последующий текст на subPage странице
    subtitle:
      "Посетители отмечают, что здесь готовят вкусный омлет с сыром, колбасой и соусом, а также тосты к нему. Кроме того, гости хвалят облепиховый чай и чай с имбирем. Интерьер кофейни выполнен в современном стиле, а из окон открывается красивый вид.",
    //место проведения
    place: "ТЦ Арбат",
    placeLink: "https://yandex.ru/maps/-/CDFlaZYo",
    //адрес
    adress: "проспект Ленина, 23А",
    //вид представления
    performance: "Кафе",
    //возростные ограничения
    age: "+6",
    //цена входа
    price: "от 200 руб.",
    //дата публикации
    data: "4 апреля 2024",
    img_1: papaMama_1,
    img_2: papaMama_2,
    img_3: papaMama_3,
    img_4: papaMama_4,
    img_5: papaMama_5,
  },
  {
    id: "3",
    //заставка объявления
    img: imgMain_3,
    //название объявления
    name: "Brooklyn BBQ Grill&Bar",
    vkLink: "https://vk.com/brooklynbbq_dd",
    // Главный текст на subPage странице
    title:
      "Бар «Бруклин BBQ» предлагает своим гостям широкий выбор мясных и рыбных блюд, а также разнообразные напитки, включая пиво, вино и коктейли.",
    // последующий текст на subPage странице
    subtitle:
      "В меню можно найти такие блюда, как стейк «Нью-Йорк», ребрышки «по-техасски», бургеры «Блю чиз» и «Филадельфия», а также пасту, салаты и десерты. Кроме того, в баре действует система скидок для именинников.",
    //место проведения
    place: "Дом торговли",
    // ссылка
    placeLink: "https://yandex.ru/maps/-/CDR0IMpG",
    //адрес
    adress: "Гвардейская улица, 21, Димитровград",
    //вид представления
    performance: "Кафе",
    //возростные ограничения
    age: "+6",
    //цена входа
    price: "от 200 руб.",
    //дата публикации
    data: "27 января 2024",
    img_1: bruklinSwiper_1,
    img_2: bruklinSwiper_2,
    img_3: bruklinSwiper_3,
    img_4: bruklinSwiper_4,
    img_5: bruklinSwiper_5,
  },
  {
    id: "4",
    //заставка объявления
    img: imgMain_4,
    //название объявления
    name: "Доставка пиццы КАЛЬЯРИ",
    vkLink: "https://vk.com/cagliari73",
    webLink: "https://cagliari-pizza.ru/",
    // Главный текст на subPage странице
    title:
      "Кальяри - это не только удовольствие каждого кусочка пиццы, но и забота о вашем вкусе и удовольствии.",
    // последующий текст на subPage странице
    subtitle:
      "В Кальяри - гордятся своими свежими ингредиентами, внимательно подобранными рецептами и быстрой доставкой прямо к вам домой. Позвольте им порадовать вас и ваших близких лучшей пиццей в городе! \n\nДоставка пиццы по городу - БЕСПЛАТНО\nДоставка на сумму менее 400 руб. - 100 руб.\nДоставка на дачный поселок - 70 руб.\nДоставка на торфболото - 70 руб.",
    //место проведения
    place: "В районе остановки ДУС",
    placeLink: "https://yandex.ru/maps/-/CDVVZJ2C",
    //адрес
    adress: "Севастопольская улица, 2",
    //вид представления
    performance: "Мюзикл",
    //возростные ограничения
    age: "+3",
    //цена входа
    price: "от 100 руб.",
    //дата публикации
    data: "27 апреля 2024",
    img_1: kaliary_1,
    img_2: kaliary_2,
    img_3: kaliary_3,
    img_4: kaliary_4,
    img_5: kaliary_5,
  },
  {
    id: "5",
    //заставка объявления
    img: imgMain_5,
    //название объявления
    name: "Итальянская пиццерия ПИЦЦАЕВСКИЙ",
    vkLink: "https://vk.com/pizzaevskydd",
    webLink: "https://www.pizzaevsky.ru/",
    // Главный текст на subPage странице
    title: "Готовим современную пиццу, с заботой о Вас!",
    // последующий текст на subPage странице
    subtitle:
      "- ПИЦЦАЕВСКИЙ строго придерживается итальянской кулинарной традиции, работая исключительно с мукой каменного помола и следуя рецептам, которые олицетворяют настоящее итальянское искусство. Благодаря этому наше тесто приобретает особую легкость и пониженную калорийность, что является явным отличием от широко распространенной американской технологии приготовления, которая часто используется.\n\n- Одна из особенностей пиццы заключается в том, что нами не используется майонез и кетчуп. Мы настаиваем на использовании только свежих и качественных ингредиентов. Только такие продукты гарантируют непревзойденный вкус наших блюд. Ежедневно нами тщательно проверяется свежесть и качество каждого ингредиента, чтобы наши клиенты получили непревзойденный вкус.\nЗабота о своём здоровье, начинается с выбора здоровых продуктов! С заботой о Вас, Ваш Пиццаевский! ",
    //место проведения
    place: "Пиццерия",
    // ссылка
    placeLink: "https://yandex.ru/maps/-/CDV4a8N4",
    //адрес
    adress: "ул. Чайкиной, 22",
    //вид представления
    performance: "",
    //возростные ограничения
    age: "+6",
    //цена входа
    price: "от 190 руб.",
    //дата публикации
    data: "4 апреля 2024",
    img_1: pizzaevskiy_1,
    img_2: pizzaevskiy_2,
    img_3: pizzaevskiy_3,
    img_4: pizzaevskiy_4,
    img_5: pizzaevskiy_5,
  },
  {
    id: "6",
    //заставка объявления
    img: imgMain_6,
    //название объявления
    name: "Сеть кофеен Coffee Green",
    vkLink: "https://vk.com/dd_coffeegreen",
    webLink: "https://taplink.cc/coffeegreen_dd",
    // Главный текст на subPage странице
    title:
      "Coffee Green - это сеть уютных кофеен в Димитровграде. Место, где можно расслабиться и отдохнуть за чашечкой кофе, попробовать новые десерты или вкусно поесть.",
    // последующий текст на subPage странице
    subtitle:
      "Coffee Green предлагает:\n\n* Широкая кофейная и чайная карта. А также лимонады, молочные коктейли, какао.\n* Завтраки в любое время дня.\n* Сэндвичи, салаты, блины.\n* Вкуснейшие десерты.\n* Детский уголок, бесплатная вода.\n\nПредзаказ и доставку можно оформить по телефонам кофеен:\n☕ ул.Гагарина, 18Б +7(904)189-8031\n☕ ул. Свирская, 47 +7(937)889-8036\n\nАренда нового зала в кофейне на Свирской 47:\n* большой стол на 10 гостей\n* зеркало, телевизор\n* детский столик\n* коллекция настольных игр.\nПри аренде зала для праздника или дня рождения можно с собой принести еду и напитки!\nПо вопросам бронирования нового зала обращайтесь по телефону +79278134050.\n\nПриложение Coffee Green - меню кофеен, оформление заказа на доставку, самовывоз или предзаказ",
    //место проведения
    place: "Кофейня",
    // ссылка
    placeLink: "https://yandex.ru/maps/-/CDV4u48y",
    //адрес
    adress: "пр. Ленина д.9",
    //вид представления
    performance: "",
    //возростные ограничения
    age: "+6",
    //цена входа
    price: "от 200 руб.",
    //дата публикации
    data: "4 апреля 2024",
    img_1: coffegreen_1,
    img_2: coffegreen_2,
    img_3: coffegreen_3,
    img_4: coffegreen_4,
    img_5: coffegreen_5,
  },
  {
    id: "7",
    //заставка объявления
    img: imgMain_7,
    //название объявления
    name: "Доставка роллов и пиццы Vkusseiko",
    webLink: "https://www.seikopizza-sushi.ru/",
    vkLink: "https://vk.com/seikopizzadd",
    // Главный текст на subPage странице
    title:
      "Vkusseiko – быстрый сервис доставки любимых блюд. Мы любим готовить и хотим только одного: накормить тебя!",
    // последующий текст на subPage странице
    subtitle:
      "Доставим пиццу, роллы, бургеры в Димитровграде и Новой Майне в течение часа. Мы приятно порадуем Вас вкусными роллами по приемлемой цене, быстрой доставкой, высоким сервисом обслуживания.\n► Принимаем заказы:\n\nПонедельник - Воскресенье с 10:00 до 22:00\n\nТы можешь сделать предварительный заказ и забрать его по адресу: г. Димитровград, пр-т Автостроителей 53А, ТЦ 'Мелекесс'",
    //место проведения
    place: "Мелекесс",
    // ссылка
    placeLink: "https://yandex.ru/maps/-/CDV4F8kv",
    //адрес
    adress: "просп. Автостроителей, 53А",
    //вид представления
    performance: "",
    //возростные ограничения
    age: "+12",
    //цена входа
    price: "от 200 руб.",
    //дата публикации
    data: "4 апреля 2024",
    img_1: seiko_1,
    img_2: seiko_2,
    img_3: seiko_3,
    img_4: seiko_4,
    img_5: seiko_5,
  },
];
export default edaPage;
