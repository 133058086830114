

//images main photo
import imgMain_1 from "../../img/zagorodom/ecoGlemping/ecoGlempingMain.webp";
import imgMain_2 from "../../img/zagorodom/dParkOtel/dParkOtelMain.webp";
import imgMain_3 from "../../img/zagorodom/sosnoviyBor/sosnpviyborMain.webp";
import imgMain_4 from "../../img/zagorodom/kurlan/kurlanMain.webp";
import imgMain_5 from "../../img/zagorodom/dubrava/dubravaMain.webp";
import imgMain_6 from "../../img/zagorodom/brown/brownMain.webp";
//ecoglemping
import ecoglemping_1 from "../../img/zagorodom/ecoGlemping/ecoGlempingSwiper.webp";
import ecoglemping_2 from "../../img/zagorodom/ecoGlemping/ecoGlempingSwiper2.webp";
import ecoglemping_3 from "../../img/zagorodom/ecoGlemping/ecoGlempingSwiper3.webp";
import ecoglemping_4 from "../../img/zagorodom/ecoGlemping/ecoGlempingSwiper4.webp";
import ecoglemping_5 from "../../img/zagorodom/ecoGlemping/ecoGlempingSwiper5.webp";
//dParkOtel
import dParkOtel_1 from "../../img/zagorodom/dParkOtel/dParkOtelSwiper1.webp";
import dParkOtel_2 from "../../img/zagorodom/dParkOtel/dParkOtelSwiper2.webp";
import dParkOtel_3 from "../../img/zagorodom/dParkOtel/dParkOtelSwiper3.webp";
import dParkOtel_4 from "../../img/zagorodom/dParkOtel/dParkOtelSwiper4.webp";
import dParkOtel_5 from "../../img/zagorodom/dParkOtel/dParkOtelSwiper5.webp";
//sosnpviybor
import sosnpviybor_1 from "../../img/zagorodom/sosnoviyBor/sosnoviyborSwiper1.webp";
import sosnpviybor_2 from "../../img/zagorodom/sosnoviyBor/sosnoviyborSwiper2.webp";
import sosnpviybor_3 from "../../img/zagorodom/sosnoviyBor/sosnoviyborSwiper3.webp";
import sosnpviybor_4 from "../../img/zagorodom/sosnoviyBor/sosnoviyborSwiper4.webp";
import sosnpviybor_5 from "../../img/zagorodom/sosnoviyBor/sosnoviyborSwiper5.webp";
//kurlan
import kurlan_1 from "../../img/zagorodom/kurlan/kurlanSwiper1.webp";
import kurlan_2 from "../../img/zagorodom/kurlan/kurlanSwiper2.webp";
import kurlan_3 from "../../img/zagorodom/kurlan/kurlanSwiper3.webp";
import kurlan_4 from "../../img/zagorodom/kurlan/kurlanSwiper4.webp";
import kurlan_5 from "../../img/zagorodom/kurlan/kurlanSwiper5.webp";
//dubrava
import dubrava_1 from "../../img/zagorodom/dubrava/dubravaSwiper1.webp";
import dubrava_2 from "../../img/zagorodom/dubrava/dubravaSwiper2.webp";
import dubrava_3 from "../../img/zagorodom/dubrava/dubravaSwiper3.webp";
import dubrava_4 from "../../img/zagorodom/dubrava/dubravaSwiper4.webp";
import dubrava_5 from "../../img/zagorodom/dubrava/dubravaSwiper5.webp";
//brown
import brown_1 from "../../img/zagorodom/brown/brownSwiper1.webp";
import brown_2 from "../../img/zagorodom/brown/brownSwiper2.webp";
import brown_3 from "../../img/zagorodom/brown/brownSwiper3.webp";
import brown_4 from "../../img/zagorodom/brown/brownSwiper4.webp";
import brown_5 from "../../img/zagorodom/brown/brownSwiper5.webp";


export const zagorodomPage = [
  {
    id: "1",
    //заставка объявления
    img: imgMain_1,
    //название объявления
    name: "Эко-Глэмпинг «Русский»",
    webLink: "https://eco-73.ru/",
    vkLink: "https://vk.com/ecorusskiy",
    link: "/zagorodomPage_1",
    // Главный текст на subPage странице
    title:
      "Эко -глэмпинг «Русский» - с заботой о Вашем отдыхе в нашей природе.",
    // последующий текст на subPage странице
    subtitle:
      "Эко-глэмпинг «Русский» - это уютные домики с видом на луга, леса и озеро.Здесь есть всё необходимое для вашего отдыха и комфорта.Всего 15 минут от Димитровграда и вы там, где можно насладиться сказочной красотой природы.\n\nЗнаем всё об отдыхе! И на каждый сезон у нас приготовлены программы, чтобы вы максимально забыли о суете.\n\nЗвоните и бронируйте свое место отдыха по тел. +7 (902) 000-01-10",
    //место проведения
    place: "Русский Мелекесс",
    // ссылка
    placeLink: "https://yandex.ru/maps/-/CDVDfMju",
    //адрес
    adress: "Тиинское сельское поселение, село Русский Мелекесс",
    //цена входа
    price: "от 1000 руб.",
    //дата публикации
    data: "4 апреля 2024",
    img_1: ecoglemping_1,
    img_2: ecoglemping_2,
    img_3: ecoglemping_3,
    img_4: ecoglemping_4,
    img_5: ecoglemping_5,
  },
  {
    id: "2",
    //заставка объявления
    img: imgMain_2,
    //название объявления
    name: "Парк отель Д-град",
    vkLink: "https://vk.com/hoteldgrad",
    webLink: "http://hotel-dgrad.ru/kontakt.html",
    link: "/zagorodomPage_2",
    // Главный текст на subPage странице
    title:
      "Парк отель Д-град рад приветствовать всех посетителей! Двери всегда открыты для Вас.",
    // последующий текст на subPage странице
    subtitle:
      "В этой гостинице в Димитровграде Вы сможете прекрасно отдохнуть, провести отпуск или просто остановиться на выходные. Это отличная возможность вырваться за пределы шумного города, отвлечься от суеты и забот, насладиться чистым лесным воздухом.",
    //место проведения
    place: "За городом",
    // ссылка
    placeLink: "https://yandex.ru/maps/-/CDVPAT8~",
    //адрес
    adress: "Курортное ш., 1, Димитровград",
    //вид представления
    performance: "",
    //возростные ограничения
    age: "-",
    //цена входа
    price: "от 1000 руб.",
    //дата публикации
    data: "4 апреля 2024",
    img_1: dParkOtel_1,
    img_2: dParkOtel_2,
    img_3: dParkOtel_3,
    img_4: dParkOtel_4,
    img_5: dParkOtel_5,
  },
  {
    id: "3",
    //заставка объявления
    img: imgMain_3,
    //название объявления
    name: "Сосновый бор",
    webLink: "https://sobodim.ru/",
    vkLink: "https://vk.com/sobodim73",
    link: "/zagorodomPage_3",
    // Главный текст на subPage странице
    title:
      "Для благотворного воздействия природы на человека совсем не обязательны далёкие поездки на южные курорты. Одним из лучших санаториев Ульяновской области, где создана крепкая лечебно- диагностическая база и широко используются природные факторы, является санаторий «Сосновый бор» г. Димитровграда.",
    // последующий текст на subPage странице
    subtitle:
      "Санаторий расположен в одном из самых живописных мест на окраине города, вдали от городского шума, в окружении вековых сосен. Удивительно чистый воздух, вечно зелёный сосновый бор, цветущие поляны, - всё здесь самой природой предназначено радовать, успокаивать, исцелять.\n Назначение видов лечения и количество процедур определяется врачом санатория с учетом показаний и противопоказаний, исходя из диагноза и степени тяжести заболевания, сопутствующей патологии, указанных в санаторно-курортной карте.",
    //место проведения
    place: "За городом",
    // ссылка
    placeLink: "https://yandex.ru/maps/-/CDR0YFPU",
    //адрес
    adress: "Димитровград, ул. Куйбышева 335",
    //вид представления
    performance: "Санаторий",
    //возростные ограничения
    age: "без ограничений",
    //цена входа
    price: "от 500 руб.",
    //дата публикации
    data: "4 апреля 2024",
    img_1: sosnpviybor_1,
    img_2: sosnpviybor_2,
    img_3: sosnpviybor_3,
    img_4: sosnpviybor_4,
    img_5: sosnpviybor_5,
  },
  {
    id: "4",
    //заставка объявления
    img: imgMain_4,
    //название объявления
    name: "Курланпарк",
    webLink: "https://kurlanpark.ru/",
    vkLink: "https://vk.com/kurlanpark",
    // Главный текст на subPage странице
    title:
      "Курланпарк — это отличное место для Вашего отдыха с друзьями и семьей!",
    // последующий текст на subPage странице
    subtitle:
      "Этой зимой Вас ждут:\n\n∗ 18 снежных горок (детские, взрослые, экстремальные);\n∗ 650 тюбингов (теперь точно хватит всем);\n∗ 3 канатные дороги (подниматься пешком не нужно);\n∗ самые настоящие северные олени;\n∗ катание на сибирских хаски;\n∗ квадроциклы, снегоходы;\n∗ путешествия по сказочному лесу на банане;\n∗ призовой стрелковый тир;\n∗ весёлые аниматоры для детей и взрослых;\n∗ большое тёплое кафе (еду и напитки теперь можно приносить с собой);\n∗ для больших компаний — уютные беседки и шатры с мангалами.\nНа территории играет музыка, а вечером зажигаются разноцветные гирлянды и фонарики, наполняя весь Парк атмосферой праздника и волшебства.\n\nВсё это — в 10 минутах езды от города Димитровграда.\n\nЕсли Вы ищите, где можно отвлечься от суеты будней и насладиться всевозможными зимними развлечениями — приезжайте к нам.\nПриезжайте в Курланпарк!",
    //место проведения
    place: "За городом",
    // ссылка
    placeLink: "https://yandex.ru/maps/-/CDVPMK78",
    //адрес
    adress: "Мелекесский район, поселок Курлан",
    //вид представления
    performance: "",
    //возростные ограничения
    age: "+3",
    //цена входа
    price: "от 400 рублей",
    //дата публикации
    data: "4 апреля 2024",
    img_1: kurlan_1,
    img_2: kurlan_2,
    img_3: kurlan_3,
    img_4: kurlan_4,
    img_5: kurlan_5,
  },
  {
    id: "5",
    //заставка объявления
    img: imgMain_5,
    //название объявления
    name: "SPA парк-отель «Дубрава»",
    webLink: "https://dubrava-73.ru/",
    vkLink: "https://vk.com/dubrava_dd",
    // Главный текст на subPage странице
    title:
      "Добро пожаловать в SPA парк-отель «Дубрава»! Здесь вас ждут комфорт и роскошь, окруженные природной красотой. ",
    // последующий текст на subPage странице
    subtitle:
      "SPA парк-отель «Дубрава» — это:\n* красивый, комфортный отдых от суеты города и работы\n* выходные в кругу семьи или компании друзей\n* активное времяпровождение или уединение в тишине\n* деловые переговоры, корпоратив, свадьба * размещение партнеров по бизнесу на время командировки или корпоративный заезд для отдыха\n* детские каникулы с семьей или с классом\nДобро пожаловать!\n\n❗Заказ и предоплата принимаются только в данной группе и на странице Дубрава Рязанова https://vk.com/dubrava2d\n\nНаши менеджеры не могут отвечать вам с какой-либо другой страницы!\n\nУльяновская область, село Рязаново, ул. Больничная, 50\n(36 км от г. Димитровграда)\n(61 км от г. Тольятти)",
    //место проведения
    place: "село Рязаново",
    // ссылка
    placeLink: "https://yandex.ru/maps/-/CDVP4Epx",
    //адрес
    adress: "Мелекесский район, с. Рязаново, ул. Больничная, д. 50, Рязаново",
    //вид представления
    //возростные ограничения
    age: "без ограничения",
    //цена входа
    price: "от 700 руб.",
    //дата публикации
    data: "4 апреля 2024",
    img_1: dubrava_1,
    img_2: dubrava_2,
    img_3: dubrava_3,
    img_4: dubrava_4,
    img_5: dubrava_5,
  },
  {
    id: "6",
    //заставка объявления
    img: imgMain_6,
    //название объявления
    name: "Mr. Brown",
    webLink: "https://hotel-brown.ru/",
    vkLink: "https://vk.com/club71764309",
    // Главный текст на subPage странице
    title:
      "Размещение и отдых людей в разных ценовых и качественных сегментах. Нами продуманы и реализованы все виды возможных потребностей наших гостей.",
    // последующий текст на subPage странице
    subtitle:
      "Гостиница Mr. Brown расположена в тихом районе, недалеко от парка и прудов. К услугам гостей номера с кондиционером, холодильником, чайником и телевизором, а также бесплатный Wi-Fi.",
    //место проведения
    place: "СОЦГОРОД",
    // ссылка
    placeLink: "https://yandex.ru/maps/-/CDVxqXPF",
    //адрес
    adress: "ул. Чкалова, 56Б",
    //вид представления
    performance: "",
    //возростные ограничения
    age: "",
    //цена входа
    price: "",
    //дата публикации
    data: "4 апреля 2024",
    img_1: brown_1,
    img_2: brown_2,
    img_3: brown_3,
    img_4: brown_4,
    img_5: brown_5,
  },
];
export default zagorodomPage;
