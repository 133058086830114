

//images main photo
import imgMain_1 from "../../img/obrazovanie/umniyYa/umniyYaMain2.webp";
import imgMain_2 from "../../img/obrazovanie/znayka/znaykaMain.webp";
import imgMain_3 from "../../img/obrazovanie/emoNeyro/emoNeyroMain.webp";
import imgMain_4 from "../../img/obrazovanie/slovenok/slovenokMain.webp";
import imgMain_5 from "../../img/obrazovanie/kvantorium/kvantoriumMain.webp";
import imgMain_6 from "../../img/obrazovanie/academyTop/academyTopSwiper1.webp";
//umniy ya
import umniyYa_1 from "../../img/obrazovanie/umniyYa/umniyYaSwiper1.webp";
import umniyYa_2 from "../../img/obrazovanie/umniyYa/umniyYaSwiper2.webp";
import umniyYa_3 from "../../img/obrazovanie/umniyYa/umniyYaSwiper3.webp";
import umniyYa_4 from "../../img/obrazovanie/umniyYa/umniyYaSwiper4.webp";
import umniyYa_5 from "../../img/obrazovanie/umniyYa/umniyYaSwiper5.webp";
//znayka
import znayka_1 from "../../img/obrazovanie/znayka/znaykaSwiper1.webp";
import znayka_2 from "../../img/obrazovanie/znayka/znaykaSwiper2.webp";
import znayka_3 from "../../img/obrazovanie/znayka/znaykaSwiper3.webp";
import znayka_4 from "../../img/obrazovanie/znayka/znaykaSwiper4.webp";
import znayka_5 from "../../img/obrazovanie/znayka/znaykaSwiper5.webp";
//emoneiro
import emoneiro_1 from "../../img/obrazovanie/emoNeyro/emoNeyroSwiper1.webp";
import emoneiro_2 from "../../img/obrazovanie/emoNeyro/emoNeyroSwiper2.webp";
import emoneiro_3 from "../../img/obrazovanie/emoNeyro/emoNeyroSwiper3.webp";
import emoneiro_4 from "../../img/obrazovanie/emoNeyro/emoNeyroSwiper4.webp";
import emoneiro_5 from "../../img/obrazovanie/emoNeyro/emoNeyroSwiper5.webp";
//slovenok
import slovenok_1 from "../../img/obrazovanie/slovenok/slovenokSwiper.webp";
import slovenok_2 from "../../img/obrazovanie/slovenok/slovenokSwiper2.webp";
import slovenok_3 from "../../img/obrazovanie/slovenok/slovenokSwiper3.webp";
import slovenok_4 from "../../img/obrazovanie/slovenok/slovenokSwiper4.webp";
import slovenok_5 from "../../img/obrazovanie/slovenok/slovenokSwiper5.webp";
//kvantorium
import kvantorium_1 from "../../img/obrazovanie/kvantorium/kvantoriumSwiper1.webp";
import kvantorium_2 from "../../img/obrazovanie/kvantorium/kvantoriumSwiper2.webp";
import kvantorium_3 from "../../img/obrazovanie/kvantorium/kvantoriumSwiper3.webp";
import kvantorium_4 from "../../img/obrazovanie/kvantorium/kvantoriumSwiper4.webp";
import kvantorium_5 from "../../img/obrazovanie/kvantorium/kvantoriumSwiper5.webp";
//academyTop
import academyTop_1 from "../../img/obrazovanie/academyTop/academyTopSwiper1.webp";
import academyTop_2 from "../../img/obrazovanie/academyTop/academyTopSwiper2.webp";
import academyTop_3 from "../../img/obrazovanie/academyTop/academyTopSwiper3.webp";
import academyTop_4 from "../../img/obrazovanie/academyTop/academyTopSwiper4.webp";
import academyTop_5 from "../../img/obrazovanie/academyTop/academyTopSwiper5.webp";



export const obrazovaniePage = [
  {
    id: "1",
    //заставка объявления
    img: imgMain_1,
    //название объявления
    name: "«УМНЫЙ Я»",
    webLink: "https://vk.com/uslugi-42642250",
    vkLink: "https://vk.com/umnyidd",
    link: "/obrazovaniePage_1",
    // Главный текст на subPage странице
    title:
      "Федеральная сеть центров включает в себя: ДИМИТРОВГРАД, СОЦГОРОД, ОЛИМП (Старый город) и ПОРТ! Эти центры предоставляют разнообразные услуги, способствуя развитию и благополучию общества.",
    // последующий текст на subPage странице
    subtitle:
      "Развитие дошкольника имеет огромное значение, так как этот период закладывает основу для будущих успехов в школе. Те навыки и умения, которые ребенок приобретет до поступления в начальную школу, будут определять его успеваемость и дальнейшие достижения.  \n\nПорт - Автостроителей, 25; Автостроителей, 30\n\nОлимп (Старый город) - Баданова, 85\n\nСоцгород - Гончарова, 11",
    //место проведения
    place: "СОЦГОРОД, ОЛИМП (Старый город), ПОРТ!",
    // ссылка
    placeLink: "https://yandex.ru/maps/-/CDRwn0Pi",
    //адрес
    adress: "Порт Автостроителей, 30",
    //вид представления
    performance: "Обучение детей",
    //возростные ограничения
    age: "+4",
    //цена входа
    price: "от 325 руб.",
    //дата публикации
    data: "4 апреля 2024",
    img_1: umniyYa_1,
    img_2: umniyYa_2,
    img_3: umniyYa_3,
    img_4: umniyYa_4,
    img_5: umniyYa_5,
  },
  {
    id: "2",
    //заставка объявления
    img: imgMain_2,
    //название объявления
    name: "Центр раннего развития «Знайка»",
    vkLink: "https://vk.com/znaykaddd",
    link: "/obrazovaniePage_2",
    // Главный текст на subPage странице
    title:
      "«Знайка» сообщество для развития вашего ребенка в городе Димитровград! Здесь каждый ребенок найдет увлекательные занятия, развивающие программы и дружную атмосферу.",
    // последующий текст на subPage странице
    subtitle:
      "«Знайка» предлагает:\nГруппы для детей от 3-7 лет\nКоррекция речи (дефектолог)\nКоррекция задержки развития\nДетский психолог\nИнтенсивная подготовка к школе\n\nДОСТУПНА ИНДИВИДУАЛЬНАЯ РАБОТА\n\nЛЮБОЙ РАЙОН: ПОРТ, СОЦГОРОД, ОЛИМП\n\nЗадайте интересующий Вас вопрос по телефону +7 904 199 56 59 или написав сообщение нашему администратору в группе",
    //место проведения
    place: "ПОРТ, СОЦГОРОД, ОЛИМП",
    // ссылка
    placeLink: "https://yandex.ru/maps/-/CDVzZ8nj",
    //адрес
    adress: "проспект Ленина, 23А",
    //вид представления
    performance: "",
    //возростные ограничения
    age: "+3",
    //цена входа
    price: "от 200 руб.",
    //дата публикации
    data: "4 апреля 2024",
    img_1: znayka_1,
    img_2: znayka_2,
    img_3: znayka_3,
    img_4: znayka_4,
    img_5: znayka_5,
  },
  {
    id: "3",
    //заставка объявления
    img: imgMain_3,
    //название объявления
    name: "ЭмоНейро",
    webLink: "https://www.emoneiro.ru/",
    vkLink: "https://vk.com/emoneirodd",
    link: "/obrazovaniePage_3",
    // Главный текст на subPage странице
    title:
      "ЭмоНейро это безопасное и просторное пространство, где ваш ребенок сможет развиваться и расти",
    // последующий текст на subPage странице
    subtitle:
      "Программы ЭмоНейро направлены на всестороннее развитие, а опытные педагоги создают атмосферу, способствующую обучению и творчеству. Тут применяются современные методики с помощью нейротренажеров и нейроигр для восстановления нейронных связей, развития речи, мышления, интеллекта, координации движения!\nЗанятия проходят в мини-группах.\n\nwww.emoneiro.ru\n8-999-193-87-70",
    //место проведения
    place: "Соцгород",
    // ссылка
    placeLink: "https://yandex.ru/maps/-/CDVzbMng",
    //адрес
    adress: "Гончарова 3,",
    //вид представления
    performance: "-",
    //возростные ограничения
    age: "+1",
    //цена входа
    price: "от 400 руб.",
    //дата публикации
    data: "4 апреля 2024",
    img_1: emoneiro_1,
    img_2: emoneiro_2,
    img_3: emoneiro_3,
    img_4: emoneiro_4,
    img_5: emoneiro_5,
  },
  {
    id: "4",
    //заставка объявления
    img: imgMain_4,
    //название объявления
    name: "Центр Детского Развития «СЛОВЁНОК»",
    vkLink: "https://vk.com/slovenok73",
    // Главный текст на subPage странице
    title:
      "Словенок - это помощь детям и их родителям. Догоним и перегоним класс по программе, позаботимся о домашних работах и заинтересуем учебой.",
    // последующий текст на subPage странице
    subtitle:
      "Поможем развить таланты и расширим кругозор!\nКомфортная среда для обучения ваших детей. Мы предлагаем различные направления для всестороннего развития с 4х лет – творческое, интеллектуальное, раннее и физическое.\nШтат квалифицированных педагогов, светлые помещения, дружелюбная атмосфера!\n\nЖдем Вас на бесплатный пробный урок!",
    //место проведения
    place: "Соцгород",
    // ссылка
    placeLink: "https://yandex.ru/maps/-/CDVWF2ML",
    //адрес
    adress: "Ленина 34\nМенделеева 27\nСлавского 16А",
    //вид представления
    performance: "",
    //возростные ограничения
    age: "Без ограничений",
    //цена входа
    price: "-",
    //дата публикации
    data: "4 апреля 2024",
    img_1: slovenok_1,
    img_2: slovenok_2,
    img_3: slovenok_3,
    img_4: slovenok_4,
    img_5: slovenok_5,
  },
  {
    id: "5",
    //заставка объявления
    img: imgMain_5,
    //название объявления
    name: "ДЕТСКИЙ ТЕХНОПАРК «КВАНТОРИУМ»",
    webLink: "http://kvantorium.dim-spo.ru/",
    vkLink: "https://vk.com/kvantum_dd",
    // Главный текст на subPage странице
    title:
      "Детский технопарк «Кванториум» — это уникальная среда для ускоренного развития ребенка по актуальным научно-исследовательским и инженерно-техническим направлениям, оснащенная высокотехнологичным оборудованием.",
    // последующий текст на subPage странице
    subtitle:
      "Детей обучают универсальным навыкам и предметным компетенциям через решение реальных кейсов по ключевым проблемам развития науки и техники, а также прививают 4K компетенции (креативность, коммуникативность, критическое мышление, умение работать в команде).",
    //место проведения
    place: "ПОРТ",
    // ссылка
    placeLink: "https://yandex.ru/maps/-/CDVlqCmr",
    //адрес
    adress: "просп. Автостроителей, 65",
    //вид представления
    performance: "",
    //возростные ограничения
    age: "+12",
    //цена входа
    price: "",
    //дата публикации
    data: "4 апреля 2024",
    img_1: kvantorium_1,
    img_2: kvantorium_2,
    img_3: kvantorium_3,
    img_4: kvantorium_4,
    img_5: kvantorium_5,
  },
  {
    id: "6",
    //заставка объявления
    img: imgMain_6,
    //название объявления
    name: "Компьютерная академия Top",
    webLink: "https://dimgrad.top-academy.ru/",
    // Главный текст на subPage странице
    title:
      "Мы делаем всё, чтобы выпускников Академии TOP принимали на работу сразу после защиты дипломов. Для этого каждый год улучшаем программу, зовём к себе лучших преподавателей и сохраняем правильную атмосферу.",
    // последующий текст на subPage странице
    subtitle:
      "Готовим программистов, дизайнеров и системных инженеров, которых нельзя заменить искусственным интеллектом. Для этого, кроме глубоких профильных знаний, учим понимать задачи, мыслить готовыми проектами и работать в команде.",
    //место проведения
    place: "СОЦГОРОД",
    // ссылка
    placeLink: "https://yandex.ru/maps/-/CDVlyT69",
    //адрес
    adress: "проспект Димитрова 2, офис 20, 1 этаж",
    //вид представления
    performance: "",
    //возростные ограничения
    age: "+12",
    //цена входа
    price: "от 200 руб.",
    //дата публикации
    data: "4 апреля 2024",
    img_1: academyTop_1,
    img_2: academyTop_2,
    img_3: academyTop_3,
    img_4: academyTop_4,
    img_5: academyTop_5,
  },
];
export default obrazovaniePage;
