

//images main photo
// import imgMain_1 from "../../img/vzroslie/blackTie/vzroslieSwiper4.webp";
import imgMain_1 from "../../img/vzroslie/blackTie/vzroslieMain.webp";
import imgMain_2 from "../../img/vzroslie/deepcafe/deepcafeMain.webp";
import imgMain_3 from "../../img/vzroslie/hubleBuble/hublBubleMain.webp";
import imgMain_4 from "../../img/vzroslie/nirvana/nirvanaMain.webp";
import imgMain_5 from "../../img/vzroslie/bloom/bloomMain.jpg";
import imgMain_6 from "../../img/vzroslie/lime/limeMain.webp";
//blackTie
import blackTie_1 from "../../img/vzroslie/blackTie/vzroslieSwiper1.webp";
import blackTie_2 from "../../img/vzroslie/blackTie/vzroslieSwiper2.webp";
import blackTie_3 from "../../img/vzroslie/blackTie/vzroslieSwiper3.webp";
import blackTie_4 from "../../img/vzroslie/blackTie/vzroslieSwiper4.webp";
import blackTie_5 from "../../img/vzroslie/blackTie/vzroslieSwiper5.webp";
//deepCafe
import deepCafe_1 from "../../img/vzroslie/deepcafe/deepcafeSwiper1.webp";
import deepCafe_2 from "../../img/vzroslie/deepcafe/deepcafeSwiper2.webp";
import deepCafe_3 from "../../img/vzroslie/deepcafe/deepcafeSwiper3.webp";
import deepCafe_4 from "../../img/vzroslie/deepcafe/deepcafeSwiper4.webp";
import deepCafe_5 from "../../img/vzroslie/deepcafe/deepcafeSwiper5.webp";
//hubleBuble
import hubleBuble_1 from "../../img/vzroslie/hubleBuble/hublBubleSwiper1.webp";
import hubleBuble_2 from "../../img/vzroslie/hubleBuble/hublBubleSwiper2.webp";
import hubleBuble_3 from "../../img/vzroslie/hubleBuble/hublBubleSwiper3.webp";
import hubleBuble_4 from "../../img/vzroslie/hubleBuble/hublBubleSwiper4.webp";
import hubleBuble_5 from "../../img/vzroslie/hubleBuble/hublBubleSwiper5.webp";
//nairvana
import nairvana_1 from "../../img/vzroslie/nirvana/nirvanaSwiper1.webp";
import nairvana_2 from "../../img/vzroslie/nirvana/nirvanaSwiper2.webp";
import nairvana_3 from "../../img/vzroslie/nirvana/nirvanaSwiper3.webp";
import nairvana_4 from "../../img/vzroslie/nirvana/nirvanaSwiper4.webp";
import nairvana_5 from "../../img/vzroslie/nirvana/nirvanaSwiper5.webp";
//bloom
import bloom_1 from "../../img/vzroslie/bloom/bloomSwiper1.jpg";
import bloom_2 from "../../img/vzroslie/bloom/bloomSwiper2.webp";
import bloom_3 from "../../img/vzroslie/bloom/bloomSwiper3.webp";
import bloom_4 from "../../img/vzroslie/bloom/bloomSwiper4.webp";
import bloom_5 from "../../img/vzroslie/bloom/bloomSwiper5.webp";
//Lime
import lime_1 from "../../img/vzroslie/lime/limeSwiper1.webp";
import lime_2 from "../../img/vzroslie/lime/limeSwiper2.webp";
import lime_3 from "../../img/vzroslie/lime/limeSwiper3.webp";
import lime_4 from "../../img/vzroslie/lime/limeSwiper4.webp";
import lime_5 from "../../img/vzroslie/lime/limeSwiper5.webp";

export const vzrosliePage = [
  {
    id: "1",
    //заставка объявления
    img: imgMain_1,
    //название объявления
    name: "«Black Tie»",
    // "webLink": "https://vk.com/uslugi-42642250",
    vkLink: "https://vk.com/black_tie",
    link: "/vzrosliePage_1",
    // Главный текст на subPage странице
    title:
      "Добро пожаловать в ресторан-караоке и ночной клуб «Black Tie»! Тут вы можете попробовать уникальное сочетание кулинарных изысков, атмосферы веселья и музыкального разнообразия. ",
    // последующий текст на subPage странице
    subtitle:
      "Ресторан Black Tie — это место, которое подходит как для молодежи, так и для людей постарше. Здесь можно заказать разнообразные коктейли, покурить кальян и спеть в караоке, а также насладиться зажигательной музыкой.\n\nИнтерьер ресторана выполнен в стильном дизайне, а персонал вежлив и внимателен к гостям. Кухня в Black Tie также заслуживает внимания, посетители отмечают, что блюда здесь очень вкусные.",
    //место проведения
    place: "СОЦГОРОД",
    // ссылка
    placeLink: "https://yandex.ru/maps/-/CDVDV6-4",
    //адрес
    adress: "просп. Ленина, 23В",
    //вид представления
    performance: "",
    //возростные ограничения
    age: "+18",
    //цена входа
    price: "от 200 руб.",
    //дата публикации
    data: "4 апреля 2024",
    img_1: blackTie_1,
    img_2: blackTie_2,
    img_3: blackTie_3,
    img_4: blackTie_4,
    img_5: blackTie_5,
  },
  {
    id: "2",
    //заставка объявления
    img: imgMain_2,
    //название объявления
    name: "«Deep cafe» lounge bar",
    vkLink: "https://vk.com/deepcafedd",
    webLink: "https://deepcafe.tilda.ws/",
    link: "/vzrosliePage_2",
    // Главный текст на subPage странице
    title:
      "Освободите голову от забот, и прочих дел. В DEEP CAFE вас ждут вкусные блюда, охлаждающие коктейли и тёплая компания.",
    // последующий текст на subPage странице
    subtitle:
      "☎ По вопросам бронирования столов обращаться по телефону 4-02-79 с 12:00.\n\nБронь стола без внесения депозита снимается по истечении 15 минут от указанного вами времени‼️",
    //место проведения
    place: "СОЦГОРОД",
    // ссылка
    placeLink: "https://yandex.ru/maps/-/CDVDVVPg",
    //адрес
    adress: "Гвардейская улица, 23",
    //вид представления
    performance: "",
    //возростные ограничения
    age: "+18",
    //цена входа
    price: "от 200 руб.",
    //дата публикации
    data: "4 апреля 2024",
    img_1: deepCafe_1,
    img_2: deepCafe_2,
    img_3: deepCafe_3,
    img_4: deepCafe_4,
    img_5: deepCafe_5,
  },
  {
    id: "3",
    //заставка объявления
    img: imgMain_3,
    //название объявления
    name: "Hubble Bubble Lounge",
    link: "/vzrosliePage_3",
    // Главный текст на subPage странице
    title:
      "Кальян-бар Hubble Bubble Lounge — это место, где вы можете расслабиться и насладиться кальяном в уютной атмосфере.",
    // последующий текст на subPage странице
    subtitle:
      "Насладитесь выбором изысканных табаков, ароматных напитков и приятной компании друзей. Тут вы найдете идеальное сочетание отдыха и удовольствия. Добро пожаловать в Hubble Bubble Lounge!",
    //место проведения
    place: "Соцгород",
    // ссылка
    placeLink: "https://yandex.ru/maps/-/CDVDVSYz",
    //адрес
    adress: "просп. Ленина, 39Г",
    //вид представления
    performance: "-",
    //возростные ограничения
    age: "+18",
    //цена входа
    price: "от 200 руб.",
    //дата публикации
    data: "4 апреля 2024",
    img_1: hubleBuble_1,
    img_2: hubleBuble_2,
    img_3: hubleBuble_3,
    img_4: hubleBuble_4,
    img_5: hubleBuble_5,
  },
  {
    id: "4",
    //заставка объявления
    img: imgMain_4,
    //название объявления
    name: "Lounge-Bar Nirvana",
    vkLink: "https://vk.com/club29657447",
    // Главный текст на subPage странице
    title:
      "Lounge-Bar NIRVANA Приглашает всех окунуться в уютную атмосферу , поиграть x-box, PS4 и насладиться и дымными облаками",
    // последующий текст на subPage странице
    subtitle:
      "Наполни свой день вкусными и ароматными облаками , в уютном месте @nirvana_dd кальянщик подберёт для тебя такой вкус , что ты не останешься равнодушным.\n\nУникальная атмосфера #nirvana_dd и кальян от лучших мастеров оставят самые приятные ощущения в этот вечер.",
    //место проведения
    place: "Олимп",
    // ссылка
    placeLink: "https://yandex.ru/maps/-/CDVD6KKL",
    //адрес
    adress: "Восточная ул., 22А",
    //вид представления
    performance: "",
    //возростные ограничения
    age: "+18",
    //цена входа
    price: "от 200 рублей",
    //дата публикации
    data: "4 апреля 2024",
    img_1: nairvana_1,
    img_2: nairvana_2,
    img_3: nairvana_3,
    img_4: nairvana_4,
    img_5: nairvana_5,
  },
  {
    id: "5",
    //заставка объявления
    img: imgMain_5,
    //название объявления
    name: "BLOOM",
    webLink: "https://bloombardd.ru/",
    // Главный текст на subPage странице
    title:
      "Кафе «Блум» – это стильное и уютное место, где гости могут насладиться вкусной едой и крафтовыми настойками.",
    // последующий текст на subPage странице
    subtitle:
      "Небольшое и концептуальное кафе в центре Соцгорода. Авторство, классика и современность. Красивый интерьер, большие панорамные окна, вид на город.",
    //место проведения
    place: "СОЦГОРОД",
    // ссылка
    placeLink: "https://yandex.ru/maps/-/CDVxYV1b",
    //адрес
    adress: "просп. Ленина, 35",
    //вид представления
    performance: "",
    //возростные ограничения
    age: "+18",
    //цена входа
    price: "",
    //дата публикации
    data: "4 апреля 2024",
    img_1: bloom_1,
    img_2: bloom_2,
    img_3: bloom_3,
    img_4: bloom_4,
    img_5: bloom_5,
  },
  {
    id: "6",
    //заставка объявления
    img: imgMain_6,
    //название объявления
    name: "LIME",
    webLink: "http://lime.dd.tilda.ws/",
    vkLink: "https://vk.com/lime_cafe_dd",
    // Главный текст на subPage странице
    title:
      "LIME это неповторимое и своеобразное заведении города Димитровграда, место где вам будут рады.",
    // последующий текст на subPage странице
    subtitle:
      "Здесь вы можете расслабиться в компании друзей, приятно провести время, перекусить,выпить пенных напитков, либо чашечку вкусного чая и насладиться дымом ароматного кальяна.У нас широкий выбор табака на любой вкус. Для удобства гостей работает Wi-Fi.\n\nПосетив наше заведение,вам захочется возвращаться сюда снова и снова!\n\nМы находимся по адресу:\nпр. Ленина 20, цокольный этаж, вход слева.\n\n⏱ Время работы:\nПонедельник 18:00-00:00\nВторник 18:00-00:00\nСреда 18:00-00:00\nЧетверг 18:00-00:00\nПятница 17:00-02:00\nСуббота 17:00-02:00\nВоскресенье 18:00-00:00\n\nПроспект Ленина, дом 20, цок. этаж.\nТелефон: +7 (902) 005-11-15",
    //место проведения
    place: "СОЦГОРОД",
    // ссылка
    placeLink: "https://yandex.ru/maps/-/CDVxY-O9",
    //адрес
    adress: "просп. Ленина, 20",
    //вид представления
    performance: "",
    //возростные ограничения
    age: "+18",
    //цена входа
    price: "",
    //дата публикации
    data: "4 апреля 2024",
    img_1: lime_1,
    img_2: lime_2,
    img_3: lime_3,
    img_4: lime_4,
    img_5: lime_5,
  },
];
export default vzrosliePage;
