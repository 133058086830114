import SwiperSubPage from "../swiperSubPage/swiperSubPage.jsx";



//obj
import {edaPage} from "../../asset/obj/eda/edaPage.js";
//images
// import iconWebsite from "../../asset/img/icon/iconWebsite.svg";
import iconVk from "../../asset/img/icon/icons-vk.svg";
//style
import "../subPages.scss";

export default function SubPageEda3(){
    const objEdaPageItem = edaPage[2];
    return (
      <>
        <div className="subItem__wrapper _container">
          <div className="subItem__images">
            <img src={objEdaPageItem.img} alt="" />
            <h3>{objEdaPageItem.name}</h3>
          </div>
          <div className="subItem__actions">
            <a className="button__buy" href="tel:+79991948245">
              Позвонить
            </a>
            <div className="subItem__actions__link">
              <a target="_blank" rel="noreferrer" href={objEdaPageItem.vkLink}>
                <img src={iconVk} alt="" />
                <strong>Vkontakte</strong>
              </a>
            </div>
          </div>
          <div className="subItem__wrapper__container">
            <div className="subItem__info">
              <h2>{objEdaPageItem.name}</h2>
              <h3>{objEdaPageItem.title}</h3>
              <p>{objEdaPageItem.subtitle}</p>
            </div>
            <div className="subItem__swiper">
              <SwiperSubPage props={edaPage[2]} />
            </div>
            <div className="subItem__contact">
              <ul className="subItem__contact__info">
                <li className="subItem__contact__info__place">
                  <strong>МЕСТО ПРОВЕДЕНИЯ</strong>
                  {objEdaPageItem.place}
                </li>
                <li className="subItem__contact__info__adress">
                  <strong>АДРЕС</strong>
                  <a
                    href={objEdaPageItem.placeLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {objEdaPageItem.adress}
                  </a>{" "}
                </li>
                <li className="subItem__contact__info__performance">
                  <strong>ВИД ПРЕДСТАВЛЕНИЯ</strong>
                  {objEdaPageItem.performance}
                </li>
                <li className="subItem__contact__info__age">
                  <strong>ВОЗРАСТНОЕ ОГРАНИЧЕНИЕ</strong>
                  {objEdaPageItem.age}
                </li>
                <li className="subItem__contact__info__price">
                  <strong>ЦЕНА</strong>
                  {objEdaPageItem.price}
                </li>
              </ul>
              <div className="subItem__contact__schedule">
                <h4>Расписание</h4>
                <div className="subItem__contact__schedule__info">
                  {/* <h5>26 декабря 2019</h5> */}
                  <h6>Пн-Вс 12:00-00:00</h6>
                </div>
              </div>
              <div className="subItem__contact__dataPubloscher">
                Опубликовано{objEdaPageItem.data}
              </div>
            </div>
          </div>
        </div>
      </>
    );
}
