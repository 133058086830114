 import { Link } from "react-router-dom";

// style
import "./header.scss";
// images
import header_logo from "../../asset/img/icon/logo3.png";
import header_location from "../../asset/img/icon/icon-location.png";
// import header_search from "../../asset/img/header/search.svg";

export default function Header() {
    //при переходе на другую страницу перетаскивает страницу наверх
    const scrollToTop = () => {
      window.scrollTo(0, 0);
    };
    return (
      <header className="header">
        <section className="header__top">
          <section className="header__top__container _container">
            <div className="header__top__logo">
              <Link onClick={scrollToTop} to={"/"}>
                <img src={header_logo} alt="logo" />
              </Link>
            </div>
            <div className="header__top__title">ChtoDo</div>
            <div className="header__top__city">
              <img src={header_location} alt="city" />
              Димитровград
            </div>
            {/* <div className="header__top__search"><label htmlFor=""><input name="s" placeholder="Искать здесь..." type="search"/><button type="submit"><img src={header_search} alt="" /></button></label></div> */}
            {/* <div className="header__top__rate">тут будет курс денег</div> */}
          </section>
        </section>
        <section className="header_center _container">
          <nav>
            <ul>
              <li>
                <Link onClick={scrollToTop} to={"kultura"}>
                  культура
                </Link>
              </li>
              <li>
                <Link onClick={scrollToTop} to={"kino"}>
                  кино
                </Link>
              </li>
              <li>
                <Link onClick={scrollToTop} to={"razvlechenie"}>
                  развлечение
                </Link>
              </li>
              <li>
                <Link onClick={scrollToTop} to={"education"}>
                  обучение
                </Link>
              </li>
              {/* <li><Link to={'kino'}>экскурсии</Link></li> */}
              <li>
                <Link onClick={scrollToTop} to={"vzroslie"}>
                  бары&#47;кальянные
                </Link>
              </li>
            </ul>
          </nav>
        </section>
        <section className="header_bottom">
          <div className="header_bottom_container _container">
            <nav>
              <ul>
                <li>
                  <Link onClick={scrollToTop} to={"food"}>
                    еда
                  </Link>
                </li>
                <li>
                  <Link onClick={scrollToTop} to={"children"}>
                    дети
                  </Link>
                </li>
                <li>
                  <Link onClick={scrollToTop} to={"nature"}>
                    за&nbsp;городом
                  </Link>
                </li>
                {/* <li ><Link to={'news'}>новости</Link></li> */}
                <li>
                  <Link onClick={scrollToTop} to={"zdorovie"}>
                    здоровье
                  </Link>
                </li>
                <li>
                  <Link onClick={scrollToTop} to={"summer"}>
                    Лето
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </section>
      </header>
    );
}
