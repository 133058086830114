import { Link } from "react-router-dom";

import logo from "../../asset/img/icon/logo3.png";
import iconTg from "../../asset/img/icon/tg.png";
import iconWp from "../../asset/img/icon/wp.png";

import "./footer.scss";

export default function Footer() {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <footer className="footer">
      <div className="footer-top">
        <div className="footer-top-logo">
          <Link onClick={scrollToTop} to={"/"}>
            <img src={logo} alt="logo" />
          </Link>
        </div>
        <div className="footer-top-social">
          <a
            href="https://t.me/o_karpenko"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={iconTg} alt="tg" />
          </a>
          <a
            href="https://wa.me/79297975091?text=%D0%9F%D1%80%D0%B8%D0%B2%D0%B5%D1%82!%20%F0%9F%91%8B%20"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={iconWp} alt="wp" />
          </a>
        </div>
      </div>
      <div className="footer-center">
        <nav className="footer-center-top">
          <ul>
            <li>
              <Link onClick={scrollToTop} to={"/"}>
                На&nbsp;главную
              </Link>
            </li>

            <li>
              <Link onClick={scrollToTop} to={"kino"}>
                Кино
              </Link>
            </li>
            <li>
              <Link onClick={scrollToTop} to={"razvlechenie"}>
                Развлечение
              </Link>
            </li>
            <li>
              <Link onClick={scrollToTop} to={"education"}>
                Обучение
              </Link>
            </li>
            <li>
              <Link onClick={scrollToTop} to={"vzroslie"}>
                бары&#47;кальянные
              </Link>
            </li>
            {/* <li>
                <Link to={"concerts"}>Концерты</Link>
              </li> */}
          </ul>
        </nav>
        <nav className="footer-center-top">
          <ul>
            <li>
              <Link onClick={scrollToTop} to={"food"}>
                Еда
              </Link>
            </li>
            {/* <li>
                <Link to={"place"}>Места</Link>
              </li> */}
            <li>
              <Link onClick={scrollToTop} to={"kultura"}>
                культура
              </Link>
            </li>
            <li>
              <Link onClick={scrollToTop} to={"children"}>
                Дети
              </Link>
            </li>
            <li>
              <Link onClick={scrollToTop} to={"nature"}>
                За&nbsp;городом
              </Link>
            </li>
            <li>
              <Link onClick={scrollToTop} to={"zdorovie"}>
                здоровье
              </Link>
            </li>
            {/* <li>
                <Link to={"news"}>Новости</Link>
              </li> */}
            {/* <li>
                <Link to={"weather"}>Погода</Link>
              </li> */}
          </ul>
        </nav>
      </div>
      <div className="footer-contact">
        <div className="footer-contact-compani">
          Если у вас возникли вопросы по материалам на сайте, вы хотите узнать
          подробности о размещении на сайте, вы можете связаться с нами в
          Telegram, WhatsApp или по почте.
        </div>
        <div className="footer-contact-item">
          <a href="mailto:1karpenko.oleg1@gmail.com">
            1karpenko.oleg1@gmail.com
          </a>
          <a href="mailto:Oleg.Developer1@yandex.ru">
            Oleg.Developer1@yandex.ru
          </a>
        </div>
      </div>
      <div className="footer__bottom">
        <h1>2024 г. ChtoDo «Что делать в димитровграде»</h1>
      </div>
    </footer>
  );
}
