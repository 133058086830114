import React from "react";
import { useNavigate } from "react-router-dom";

import "./noMatch.scss";

// import bgNotPage from "../../asset/img/page404/bg-404.png";
import bgNotPage from "../../asset/img/page404/bg-404.svg";
import arrow404 from "../../asset/img/page404/arrow404.png";

export default function NoMatch() {
  const navigate = useNavigate();

  const handleRedirect = () => {
    navigate("/");
  };

  return (
    <section className="page_404">
      <div className="page_404__text">
        <h2>Ooops...</h2>
        <h3>Страница не найдена</h3>
        <p>
          Ошибка 404 возникает, когда пользователь или поисковая система
          обращаются к несуществующей странице, удалённому контенту или
          недоступному файлу.
        </p>
        <button onClick={handleRedirect} className="button_404">
          <b>Перейти на главную</b>
          <img className="button_404__arrow" src={arrow404} alt="arrow404" />
        </button>
      </div>
      <div className="page_404__images">
        <img src={bgNotPage} alt="page background 404" />
      </div>
    </section>
  );
}
