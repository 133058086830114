import SwiperSubPage from "../swiperSubPage/swiperSubPage.jsx";

//obj
import {edaPage} from "../../asset/obj/eda/edaPage.js";
//images
// import iconWebsite from "../../asset/img/icon/iconWebsite.svg";
//style
import "../subPages.scss";

export default function SubPageEda2(){
    const edaPageItem = edaPage[1];
    return (
      <>
        <div className="subItem__wrapper _container">
          <div className="subItem__images">
            <img src={edaPageItem.img} alt="" />
            <h3>{edaPageItem.name}</h3>
          </div>
          <div className="subItem__actions">
            <a
              className="button__buy"
              target="_blank"
              rel="noreferrer"
              href={edaPageItem.placeLink}
            >
              Отправиться в кафе
            </a>
            {/* <div className="subItem__actions__link"><a target="_blank" rel="noreferrer" href="google.com"><img src={iconWebsite} alt="" /><strong>WebSite</strong></a></div> */}
          </div>
          <div className="subItem__wrapper__container">
            <div className="subItem__info">
              <h2>{edaPageItem.name}</h2>
              <h3>{edaPageItem.title}</h3>
              <p>{edaPageItem.subtitle}</p>
            </div>
            <div className="subItem__swiper">
              <SwiperSubPage props={edaPage[1]} />
            </div>
            <div className="subItem__contact">
              <ul className="subItem__contact__info">
                <li className="subItem__contact__info__place">
                  <strong>МЕСТО ПРОВЕДЕНИЯ</strong>
                  {edaPageItem.place}
                </li>
                <li className="subItem__contact__info__adress">
                  <strong>АДРЕС</strong>
                  <a
                    href={edaPageItem.placeLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {edaPageItem.adress}
                  </a>
                </li>
                <li className="subItem__contact__info__performance">
                  <strong>ВИД ПРЕДСТАВЛЕНИЯ</strong>
                  {edaPageItem.performance}
                </li>
                <li className="subItem__contact__info__age">
                  <strong>ВОЗРАСТНОЕ ОГРАНИЧЕНИЕ</strong>
                  {edaPageItem.age}
                </li>
                <li className="subItem__contact__info__price">
                  <strong>ЦЕНА</strong>
                  {edaPageItem.price}
                </li>
              </ul>
              <div className="subItem__contact__schedule">
                <h4>Расписание</h4>
                <div className="subItem__contact__schedule__info">
                  {/* <h5>26 декабря 2023 – 31 марта 2024</h5> */}
                  <h6>
                    пн–пт 9:00 - 20:00
                    <br />
                    вс 10:00 - 19:00
                  </h6>
                </div>
              </div>
              <div className="subItem__contact__dataPubloscher">
                Опубликовано {edaPageItem.data}
              </div>
            </div>
          </div>
        </div>
      </>
    );
}
