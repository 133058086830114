

//images main photo
import imgMain_1 from "../../img/kino/praga/pragaMain.webp";
import imgMain_2 from "../../img/kino/puschistiyvoya/puschistiyvoyaMain.webp";
import imgMain_3 from "../../img/kino/ministerstvoNedjentDel/ministerstvoNedjentDelMain.webp";
import imgMain_4 from "../../img/kino/posledniyDrag/posledniyDragMain.webp";
import imgMain_5 from "../../img/kino/bugimen/bugimenMainjpg.webp";
import imgMain_6 from "../../img/kino/nakrauZemli/nakrauZemliMain.webp";
//praga
import praga_1 from "../../img/kino/praga/pragaSwiper1.webp";
import praga_2 from "../../img/kino/praga/pragaSwiper2.webp";
import praga_3 from "../../img/kino/praga/pragaSwiper3.webp";
import praga_4 from "../../img/kino/praga/pragaSwiper4.webp";
import praga_5 from "../../img/kino/praga/pragaSwiper5.webp";
//puschistiyvoya
import puschistiyvoya_1 from "../../img/kino/puschistiyvoya/puschistiyvoyaSwiper1.webp";
import puschistiyvoya_2 from "../../img/kino/puschistiyvoya/puschistiyvoyaSwiper2.webp";
import puschistiyvoya_3 from "../../img/kino/puschistiyvoya/puschistiyvoyaSwiper3.webp";
import puschistiyvoya_4 from "../../img/kino/puschistiyvoya/puschistiyvoyaSwiper4.webp";
import puschistiyvoya_5 from "../../img/kino/puschistiyvoya/puschistiyvoyaSwiper5.webp";
//ministerstvoNedjentDel
import ministerstvoNedjentDel_1 from "../../img/kino/ministerstvoNedjentDel/ministerstvoNedjentDelSwiper1.webp";
import ministerstvoNedjentDel_2 from "../../img/kino/ministerstvoNedjentDel/ministerstvoNedjentDelSwiper2.webp";
import ministerstvoNedjentDel_3 from "../../img/kino/ministerstvoNedjentDel/ministerstvoNedjentDelSwiper3.webp";
import ministerstvoNedjentDel_4 from "../../img/kino/ministerstvoNedjentDel/ministerstvoNedjentDelSwiper4.webp";
import ministerstvoNedjentDel_5 from "../../img/kino/ministerstvoNedjentDel/ministerstvoNedjentDelSwiper5.webp";
//posledniyDrag
import posledniyDrag_1 from "../../img/kino/posledniyDrag/posledniyDragSwiper1.webp";
import posledniyDrag_2 from "../../img/kino/posledniyDrag/posledniyDragSwiper2.webp";
import posledniyDrag_3 from "../../img/kino/posledniyDrag/posledniyDragSwiper3.webp";
import posledniyDrag_4 from "../../img/kino/posledniyDrag/posledniyDragSwiper4.webp";
import posledniyDrag_5 from "../../img/kino/posledniyDrag/posledniyDragSwiper5.webp";
//bugimen
import bugimen_1 from "../../img/kino/bugimen/bugimenSwiper1.webp";
import bugimen_2 from "../../img/kino/bugimen/bugimenSwiper2.webp";
import bugimen_3 from "../../img/kino/bugimen/bugimenSwiper3.webp";
import bugimen_4 from "../../img/kino/bugimen/bugimenSwiper4.webp";
import bugimen_5 from "../../img/kino/bugimen/bugimenSwiper5.webp";
//nakrauZemli
import nakrauZemli_1 from "../../img/kino/nakrauZemli/nakrauZemliSwiper1.webp";
import nakrauZemli_2 from "../../img/kino/nakrauZemli/nakrauZemliSwiper2.webp";
import nakrauZemli_3 from "../../img/kino/nakrauZemli/nakrauZemliSwiper3.webp";
import nakrauZemli_4 from "../../img/kino/nakrauZemli/nakrauZemliSwiper4.webp";
import nakrauZemli_5 from "../../img/kino/nakrauZemli/nakrauZemliSwiper5.webp";


export const kinoPage = [
  {
    id: "1",
    //заставка объявления
    img: imgMain_1,
    //название объявления
    name: "Прага CINEMA",
    vkLink: "https://vk.com/kinopraga",
    webLink: "http://center-prague.ru/tickets/",
    link: "/kinoPage_1",
    // Главный текст на subPage странице
    title:
      "Сеть современных кинотеатров Прага CINEMA приглашает вас на захватывающие кинособытия!",
    // последующий текст на subPage странице
    subtitle:
      "ПРАГА: +7-927-809-55-88\nАРСЕНАЛ: +7-927-27-08-338\n\nОтдохните от повседневной суеты в уютных креслах, окунитесь в мир грандиозных приключений и невероятных эмоций. Прага CINEMA предлагает широкий выбор фильмов для каждого вкуса и возраста. Погрузитесь в атмосферу кино и насладитесь качественным звуком и ярким изображением. Вы найдете всё для незабываемого кинопраздника!",
    //место проведения
    place: "ТРЦ Арсенал\nТЦ Прага",
    // ссылка
    placeLink1: "https://yandex.ru/maps/-/CDbQq4I5",
    placeLink2: "https://yandex.ru/maps/-/CDbQqFLS",
    //адрес
    adress1: "просп. Ленина, 35Б",
    adress2: "Хмельницкого ул., д.100",
    //цена входа
    price: "-",
    //дата публикации
    data: "4 апреля 2024",
    img_1: praga_1,
    img_2: praga_2,
    img_3: praga_3,
    img_4: praga_4,
    img_5: praga_5,
  },
  {
    id: "2",
    //заставка объявления
    img: imgMain_2,
    //название объявления
    name: "«Пушистый вояж»",
    vkLink: "https://vk.com/kinopraga?w=wall-118851332_50471",
    link: "/kinoPage_1",
    // Главный текст на subPage странице
    title:
      "Премьера трейлера к захватывающей анимации для всей семьи «Пушистый вояж»!",
    // последующий текст на subPage странице
    subtitle:
      "Во время переезда домашние любимцы, Педро и Грейси, теряют своих хозяев. Оказавшись в пугающем и неизвестном мире, они пытаются воссоединиться с семьей. На пути их ждет множество приключений и опасностей, справиться с которыми можно, только действуя сообща. Смогут ли они разрешить свои разногласия и вернуться домой?\n\nНе пропустите на больших экранах с 23 мая! 6+",
    //место проведения
    place: "Прага CINEMA",
    // ссылка
    placeLink1: "https://yandex.ru/maps/-/CDbQq4I5",
    placeLink2: "https://yandex.ru/maps/-/CDbQqFLS",
    //адрес
    adress1: "просп. Ленина, 35Б",
    adress2: "Хмельницкого ул., д.100",
    //цена входа
    price: "",
    //дата публикации
    data: "4 апреля 2024",
    img_1: puschistiyvoya_1,
    img_2: puschistiyvoya_2,
    img_3: puschistiyvoya_3,
    img_4: puschistiyvoya_4,
    img_5: puschistiyvoya_5,
  },
  {
    id: "3",
    //заставка объявления
    img: imgMain_3,
    //название объявления
    name: "«Министерство неджентльменских дел»",
    vkLink:
      "https://vk.com/kinopraga?z=photo-118851332_457271312%2Falbum-118851332_00%2Frev",
    link: "/kinoPage_3",
    // Главный текст на subPage странице
    title:
      "Этих ребят так просто не потопить. «Министерство неджентльменских дел» все-таки выйдет в российский прокат!",
    // последующий текст на subPage странице
    subtitle:
      "«Министерство неджентльменских дел» 2024 года - это не просто фильм, это настоящее произведение искусства, которое поражает своей красотой и глубиной. Эта картина точно заслуживает внимания зрителей, жаждущих увидеть нечто новое и уникальное в мире кинематографа.",
    //место проведения
    place: "Прага CINEMA",
    // ссылка
    placeLink1: "https://yandex.ru/maps/-/CDbQq4I5",
    placeLink2: "https://yandex.ru/maps/-/CDbQqFLS",
    //адрес
    adress1: "просп. Ленина, 35Б",
    adress2: "Хмельницкого ул., д.100",
    //цена входа
    price: "",
    //дата публикации
    data: "4 апреля 2024",
    img_1: ministerstvoNedjentDel_1,
    img_2: ministerstvoNedjentDel_2,
    img_3: ministerstvoNedjentDel_3,
    img_4: ministerstvoNedjentDel_4,
    img_5: ministerstvoNedjentDel_5,
  },
  {
    id: "4",
    //заставка объявления
    img: imgMain_4,
    //название объявления
    name: "«Последний дракон»",
    vkLink: "https://vk.com/kinopraga?w=wall-118851332_50509",
    link: "/kinoPage_4",
    // Главный текст на subPage странице
    title: "В каждом есть сила, нужно лишь её пробудить!",
    // последующий текст на subPage странице
    subtitle:
      "Говорящие драконы когда-то жили в гармонии с людьми. Но теперь их род в опасности, и только одна отважная девочка может спасти магических существ. Ее ждет полное опасности путешествие через самые волшебные места древнего Китая.",
    //место проведения
    place: "Прага CINEMA",
    // ссылка
    placeLink1: "https://yandex.ru/maps/-/CDbQq4I5",
    placeLink2: "https://yandex.ru/maps/-/CDbQqFLS",
    //адрес
    adress1: "просп. Ленина, 35Б",
    adress2: "Хмельницкого ул., д.100",
    //цена входа
    price: "",
    //дата публикации
    data: "4 апреля 2024",
    img_1: posledniyDrag_1,
    img_2: posledniyDrag_2,
    img_3: posledniyDrag_3,
    img_4: posledniyDrag_4,
    img_5: posledniyDrag_5,
  },
  {
    id: "5",
    //заставка объявления
    img: imgMain_5,
    //название объявления
    name: "«Бугимен. Царство мертвых»",
    vkLink: "https://vk.com/kinopraga?w=wall-118851332_50512",
    link: "/kinoPage_5",
    // Главный текст на subPage странице
    title:
      "К Сан-вону заявляется специалист по потустороннему, уверенный, что девочку забрал шкаф в её комнате.",
    // последующий текст на subPage странице
    subtitle:
      "Архитектор Сан Вон с маленькой дочкой переезжают в новый дом. В один из дней совершенно неожиданно девочка исчезает. Развешанные по городу объявления о ее пропаже дают неожиданный эффект – к Сан Вону приходит лучший в стране парапсихолог и предлагает помочь. Он уверен что дочь архитектора, как и многие другие дети, не просто потерялась – ее забрала зловещая сущность, живущая в стенном шкафу! Теперь Сан Вон должен сам отправиться в потусторонний мир и сразиться со злом.",
    //место проведения
    place: "Прага CINEMA",
    // ссылка
    placeLink1: "https://yandex.ru/maps/-/CDbQq4I5",
    placeLink2: "https://yandex.ru/maps/-/CDbQqFLS",
    //адрес
    adress1: "просп. Ленина, 35Б",
    adress2: "Хмельницкого ул., д.100",
    //цена входа
    price: "",
    age: "+18",
    //дата публикации
    data: "4 апреля 2024",
    img_1: bugimen_1,
    img_2: bugimen_2,
    img_3: bugimen_3,
    img_4: bugimen_4,
    img_5: bugimen_5,
  },
  {
    id: "6",
    //заставка объявления
    img: imgMain_6,
    //название объявления
    name: "«На краю земли»",
    vkLink:
      "https://vk.com/feed?q=%23%D0%9D%D0%B0%D0%9A%D1%80%D0%B0%D1%8E%D0%97%D0%B5%D0%BC%D0%BB%D0%B8&section=search&w=wall-118851332_50519",
    link: "/kinoPage_6",
    // Главный текст на subPage странице
    title:
      "На краю света, где правят древние племена маори, нет места чужакам.",
    // последующий текст на subPage странице
    subtitle:
      " Томас Манро — бывший солдат, ставший проповедником-мирянином. В надежде искупить свое темное прошлое он прибывает в Новую Зеландию, чтобы служить первым британским колонистам, однако вскоре оказывается в эпицентре столкновения враждующих племен маори",
    //место проведения
    place: "Прага CINEMA",
    // ссылка
    placeLink1: "https://yandex.ru/maps/-/CDbQq4I5",
    placeLink2: "https://yandex.ru/maps/-/CDbQqFLS",
    //адрес
    adress1: "просп. Ленина, 35Б",
    adress2: "Хмельницкого ул., д.100",
    //цена входа
    price: "",
    age: "+12",
    //дата публикации
    data: "4 апреля 2024",
    img_1: nakrauZemli_1,
    img_2: nakrauZemli_2,
    img_3: nakrauZemli_3,
    img_4: nakrauZemli_4,
    img_5: nakrauZemli_5,
  },
];
export default kinoPage;
