

//images main photo
import imgMain_1 from "../../img/deti/activePark/activeParkMain.webp";
import imgMain_2 from "../../img/deti/adrenalin/adrenalinMain.webp";
import imgMain_3 from "../../img/deti/schiskin/schiskinMain2.webp";
import imgMain_4 from "../../img/deti/luna/lunaMain.webp";
import imgMain_5 from "../../img/deti/virus/virusMain.webp";
import imgMain_6 from "../../img/deti/blackDor/blackDorMain.webp";

//activePark
import activePark_1 from "../../img/deti/activePark/activeParkSwiper1.webp";
import activePark_2 from "../../img/deti/activePark/activeParkSwiper2.webp";
import activePark_3 from "../../img/deti/activePark/activeParkSwiper3.webp";
import activePark_4 from "../../img/deti/activePark/activeParkSwiper4.webp";
import activePark_5 from "../../img/deti/activePark/activeParkSwiper5.webp";
//adernalin
import adernalin_1 from "../../img/deti/adrenalin/adrenalinSwiper1.webp";
import adernalin_2 from "../../img/deti/adrenalin/adrenalinSwiper2.webp";
import adernalin_3 from "../../img/deti/adrenalin/adrenalinSwiper3.webp";
import adernalin_4 from "../../img/deti/adrenalin/adrenalinSwiper4.webp";
import adernalin_5 from "../../img/deti/adrenalin/adrenalinSwiper5.webp";
//schiskin
import schiskin_1 from "../../img/deti/schiskin/schiskinSwiper1.webp";
import schiskin_2 from "../../img/deti/schiskin/schiskinSwiper2.webp";
import schiskin_3 from "../../img/deti/schiskin/schiskinSwiper3.webp";
import schiskin_4 from "../../img/deti/schiskin/schiskinSwiper4.webp";
import schiskin_5 from "../../img/deti/schiskin/schiskinSwiper5.webp";
//luna
import luna_1 from "../../img/deti/luna/lunaSwiper1.webp";
import luna_2 from "../../img/deti/luna/lunaSwiper2.webp";
import luna_3 from "../../img/deti/luna/lunaSwiper3.webp";
import luna_4 from "../../img/deti/luna/lunaSwiper4.webp";
import luna_5 from "../../img/deti/luna/lunaSwiper5.webp";
//virus
import virus_1 from "../../img/deti/virus/virusSwiper1.webp";
import virus_2 from "../../img/deti/virus/virusSwiper2.webp";
import virus_3 from "../../img/deti/virus/virusSwiper3.webp";
import virus_4 from "../../img/deti/virus/virusSwiper4.webp";
import virus_5 from "../../img/deti/virus/virusSwiper5.webp";
//blackDor
import blackDor_1 from "../../img/deti/blackDor/blackDorSwiper1.webp";
import blackDor_2 from "../../img/deti/blackDor/blackDorSwiper2.webp";
import blackDor_3 from "../../img/deti/blackDor/blackDorSwiper3.webp";
import blackDor_4 from "../../img/deti/blackDor/blackDorSwiper4.webp";
import blackDor_5 from "../../img/deti/blackDor/blackDorSwiper5.webp";





export const detiPage = [
  {
    id: "1",
    //заставка объявления
    img: imgMain_1,
    //название объявления
    name: "Активити Парк",
    // "webLink": "https://eco-73.ru/",
    vkLink: "https://vk.com/activiti_park_dd",
    link: "/zagorodomPage_1",
    // Главный текст на subPage странице
    title:
      "АКТИВИТИ ПАРК -это целый мир веселья, игр и самых потрясающих детских эмоций!",
    // последующий текст на subPage странице
    subtitle:
      "АКТИВИТИ ПАРК предлагает:\n\n1 час - 500 руб (450 руб с картой постоянного клиента)\n2 часа - 700 руб (650 руб с картой постоянного клиента)\nБезлимитный - 800 руб (750 руб с картой постоянного клиента)\n\nСкидки именинникам! С картой постоянного клиента первый час - 350 руб, без карты - 450 руб.\nБезлимитный 650 руб\nТолько 1 час !!!\n\nСкидка не распространяется на безлимитный абонемент и друзей клиента.\nВ наличии скидочные карты которые дают скидку 50р на посещение",
    //место проведения
    place: "ТК «Мега»",
    // ссылка
    placeLink: "https://yandex.ru/maps/-/CDVDrKL9",
    //адрес
    adress: "просп. Ленина, 35А",
    //цена входа
    price: "от 350 руб.",
    //дата публикации
    data: "4 апреля 2024",
    img_1: activePark_1,
    img_2: activePark_2,
    img_3: activePark_3,
    img_4: activePark_4,
    img_5: activePark_5,
  },
  {
    id: "2",
    //заставка объявления
    img: imgMain_2,
    //название объявления
    name: "Парк приключений Адреналин",
    vkLink: "https://vk.com/parkadrenalin",
    webLink: "https://parkadrenalin.ru/",
    link: "/vzrosliePage_2",
    // Главный текст на subPage странице
    title:
      "В Парк приключений Адреналин знают свое дело до мелочей, безопасность и качество для них на первом месте.",
    // последующий текст на subPage странице
    subtitle:
      "Их веревочные парки популярны среди детей и молодежи!\n\nДетский развлекательный центр «Адреналин» - огромная батутная арена, большой лабиринт для Ваших детишек, четыре стилизованные комнаты под любимых героев фильмов и мультиков.\n\nМы украсим Ваш праздник воздушными шарами. Большой выбор аниматоров и квестов для вашего праздника.\n\nООО «Адреналин» с 2012 года занимается оказанием услуг в сфере активного отдыха и развлечении.",
    //место проведения
    place: "Парк Западный",
    // ссылка
    placeLink: "https://yandex.ru/maps/-/CDVxBGJO",
    //адрес
    adress: "Димитровград, Мулловское шоссе Парк Западный",
    //вид представления
    performance: "",
    //возростные ограничения
    age: "",
    //цена входа
    price: "",
    //дата публикации
    data: "4 апреля 2024",
    img_1: adernalin_1,
    img_2: adernalin_2,
    img_3: adernalin_3,
    img_4: adernalin_4,
    img_5: adernalin_5,
  },
  {
    id: "3",
    //заставка объявления
    img: imgMain_3,
    //название объявления
    name: "Экопарк Шишкин",
    vkLink: "https://vk.com/shishkinpark",
    webLink: "https://shishkin73.ru/",
    link: "/vzrosliePage_3",
    // Главный текст на subPage странице
    title: "Парк семейного отдыха в Димитровграде",
    // последующий текст на subPage странице
    subtitle:
      "Экопарк Шишкин предлагает:\n\nЧасы работы парка\nСуббота, воскресенье 10:00 -22:00.\n \nЭтой весной все в Шишкин парк!\n-Хаски и метисы, экскурсии на Волчью поляну\n-Фотосессия с собаками\n-Догтрекинг\n-Прогулка к озеру\n-Удобная навигация внутри парка\n-Профессиональный тир\n-Уютное кафе\n-Приятная сопровождающая музыка\n-Большущая мангальная зона\n-Бесплатная парковка\n-Отдыхай рядом с домом!\n-Отдыхай с комфортом!\n-Отдыхай без переплат за такси!\n-Доступные цены\n \n-Оцени со своими близкими новое место в твоем городе\n \nг. Димитровград, Мулловское шоссе, 32\n \nКасса тел: +7-927-209-06-01\n\nПо вопросам проведения групповых программ для классов и корпоративных мероприятий тел: +7-902-582-08-08",
    //место проведения
    place: "Шишкин парк",
    // ссылка
    placeLink: "https://yandex.ru/maps/-/CDVxFWIx",
    //адрес
    adress: "Димитровград, Шишкин парк",
    //вид представления
    performance: "-",
    //возростные ограничения
    age: "",
    //цена входа
    price: "",
    //дата публикации
    data: "4 апреля 2024",
    img_1: schiskin_1,
    img_2: schiskin_2,
    img_3: schiskin_3,
    img_4: schiskin_4,
    img_5: schiskin_5,
  },
  {
    id: "4",
    //заставка объявления
    img: imgMain_4,
    //название объявления
    name: "Луна",
    // Главный текст на subPage странице
    title:
      "Отличное место для отдыха компанией взрослых и детей, взрослые отдыхают за большим столом, дети на батутат.",
    // последующий текст на subPage странице
    // "subtitle": "Наполни свой день вкусными и ароматными облаками , в уютном месте @nirvana_dd кальянщик подберёт для тебя такой вкус , что ты не останешься равнодушным.\n\nУникальная атмосфера #nirvana_dd и кальян от лучших мастеров оставят самые приятные ощущения в этот вечер.",
    //место проведения
    place: "Академгородок",
    // ссылка
    placeLink: "https://yandex.ru/maps/-/CDbAa86d",
    //адрес
    adress: "просп. Димитрова, 1Б",
    //вид представления
    performance: "",
    //возростные ограничения
    age: "",
    //цена входа
    price: "",
    //дата публикации
    data: "4 апреля 2024",
    img_1: luna_1,
    img_2: luna_2,
    img_3: luna_3,
    img_4: luna_4,
    img_5: luna_5,
  },
  {
    id: "5",
    //заставка объявления
    img: imgMain_5,
    //название объявления
    name: "ViRus",
    vkLink: "https://vk.com/dimitrovgrad_vr",
    // Главный текст на subPage странице
    title:
      "Виртуальная реальность (VR) игры, аттракционы. Для взрослых и детей!",
    // последующий текст на subPage странице
    subtitle:
      "Организация праздников, дни рождения, корпоративы, выпускные и другие мероприятия\n\n- Квесты в реальности\n- Выездные квесты\n- Аттракцион виртуальной реальности\n\nтел. 9-03-50",
    //место проведения
    place: "ПОРТ",
    // ссылка
    placeLink: "https://yandex.ru/maps/-/CDbAiEKu",
    //адрес
    adress: "Автостроиетелей 61",
    //вид представления
    performance: "",
    //возростные ограничения
    age: "+6",
    //цена входа
    price: "",
    //дата публикации
    data: "4 апреля 2024",
    img_1: virus_1,
    img_2: virus_2,
    img_3: virus_3,
    img_4: virus_4,
    img_5: virus_5,
  },
  {
    id: "6",
    //заставка объявления
    img: imgMain_6,
    //название объявления
    name: "ЧЕРНАЯ ДВЕРЬ Квесты",
    vkLink: "https://vk.com/bdoor",
    // Главный текст на subPage странице
    title: "Квесты, развлекательный центр, игровой клуб",
    // последующий текст на subPage странице
    subtitle:
      "Организация праздников, дни рождения, корпоративы, выпускные и другие мероприятия, детские дни рождения\n- Квесты в реальности\n- Выездные квесты\n- Аттракцион виртуальной реальности\n- Банкетный зал\n\nДля вас ежедневно работают квесты:\nИгра престолов: Семь королевств\nОбитель зла 2: Апокалипсис (квест с актером)\nОграбление банка - 2\nФредди против Джейсона (квест с актером)\n\nВыездные и стационарные квесты для взрослых и детей. vk.com/bdoorkids\n- Квест Игра в кальмара\n- Квест AMONG US\n- Квест Форт Боярд\n- Квест Остров сокровищ. Пираты Карибского моря\n- Квест в стиле Майн крафт\n- Квест в стиле Brawl_Stars\n- Банкетная зона для чаепития\n\nГрафик работы:\n11:00 - 20:00\n\nПредварительная запись по тел:\n+7-904-199-03-50\n8 (84235) 9-03-50",
    //место проведения
    place: "СОЦГОРОД",
    // ссылка
    placeLink: "https://yandex.ru/maps/-/CDbAy-p~",
    //адрес
    adress: "Гвардейская ул., 44",
    //вид представления
    performance: "",
    //возростные ограничения
    age: "",
    //цена входа
    price: "",
    //дата публикации
    data: "4 апреля 2024",
    img_1: blackDor_1,
    img_2: blackDor_2,
    img_3: blackDor_3,
    img_4: blackDor_4,
    img_5: blackDor_5,
  },
];
export default detiPage;
