
// import Swiper core and required modules
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import "./swiperSubPage.scss";
// obj


export default function SwiperSubPage({props}) {
  return (
    <div className="subPageSwiper__wrapper">
            <Swiper
                modules={[Navigation]}
                spaceBetween={20}
                slidesPerView={3}
                breakpoints={{
                    840: {
                      slidesPerView: 3,
                    },
                    500: {
                      slidesPerView: 2,
                    },
                    320: {
                      slidesPerView: 1,
                    },
                  }}
                navigation
                loop = {true}
                >
                        <SwiperSlide>
                            <div className="subPageSwiper__item">
                                <img src={props.img_1} alt="" />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="subPageSwiper__item">
                                <img src={props.img_2} alt="" />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="subPageSwiper__item">
                                <img src={props.img_3} alt="" />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="subPageSwiper__item">
                                <img src={props.img_4} alt="" />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="subPageSwiper__item">
                                <img src={props.img_5} alt="" />
                            </div>
                        </SwiperSlide>
            </Swiper>
    </div>
  );
};

