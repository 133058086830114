

import SwiperSubPage from "../swiperSubPage/swiperSubPage.jsx";

//obj
import {kinoPage} from "../../asset/obj/kino/kinoPage.js"; //вся информация о бизнесе
//images
import iconWebsite from "../../asset/img/icon/iconWebsite.svg";
import iconVk from "../../asset/img//icon/icons-vk.svg";

//style
import "../subPages.scss";
const objPageInfo = kinoPage[0];
export default function SubPageKino1(){
    return(
        <div className="subItem__wrapper _container">
            <div className="subItem__images"><img src={objPageInfo.img} alt="" /><h3>{objPageInfo.name}</h3></div>
            <div className="subItem__actions">
                <a className="button__buy" target="_blank" rel="noreferrer" href="tel:+79278095588">Позвонить</a>
                <div className="subItem__actions__link">
                    <a target="_blank" rel="noreferrer" href={objPageInfo.webLink}><img src={iconWebsite} alt="" /><strong>Веб-сайт</strong></a>
                    <a target="_blank" rel="noreferrer" href={objPageInfo.vkLink}><img src={iconVk} alt="" /><strong>Вконтакте</strong></a>
                </div>
            </div>
            <div className="subItem__wrapper__container">
            <div className="subItem__info">
                <h2>{objPageInfo.name}</h2>
                <h3>{objPageInfo.title}</h3>
                <p>{objPageInfo.subtitle}</p>
            </div>
            <div className="subItem__swiper">
                <SwiperSubPage props={kinoPage[0]}/>
            </div>
            <div className="subItem__contact">
                <ul className="subItem__contact__info">
                    <li className="subItem__contact__info__place"><strong>МЕСТО ПРОВЕДЕНИЯ</strong>{objPageInfo.place}</li>
                    <li className="subItem__contact__info__adress"><strong>АДРЕС</strong><a href={objPageInfo.placeLink1} target="_blank" rel="noopener noreferrer">{objPageInfo.adress1}</a><a href={objPageInfo.placeLink2} target="_blank" rel="noopener noreferrer">{objPageInfo.adress2}</a> </li>
                    {/* <li className="subItem__contact__info__performance"><strong>ВИД ПРЕДСТАВЛЕНИЯ</strong>{objPageInfo.performance}</li> */}
                    {/* <li className="subItem__contact__info__age"><strong>ВОЗРАСТНОЕ ОГРАНИЧЕНИЕ</strong>{objPageInfo.age}</li> */}
                    {/* <li className="subItem__contact__info__price"><strong>ЦЕНА</strong>{objPageInfo.price}</li> */}
                </ul>
                <div className="subItem__contact__schedule">
                    {/* <h4>Расписание</h4> */}
                    <div className="subItem__contact__schedule__info">
                        {/* <h5>26 декабря 2023 – 31 марта 2024</h5> */}
                        {/* <h6>вт–пт 19:00<br />сб, вс 13:00<br />сб, вс 18:00</h6> */}
                    </div>
                </div>
                <div className="subItem__contact__dataPubloscher">Опубликовано {objPageInfo.data}</div>
            </div>
            </div>
            
        </div>
    )
}
