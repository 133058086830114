

//images main photo
import imgMain_1 from "../../img/kultura/podium/podiumMain.webp";
import imgMain_2 from "../../img/kultura/dramteatr/dranteatrMain.webp";
import imgMain_3 from "../../img/kultura/gorizont/gorizontMain.webp";
import imgMain_4 from "../../img/kultura/slavskogo/slavskogoMain.webp";
import imgMain_5 from "../../img/kultura/iskustvo/iskustvoMain.webp";
import imgMain_6 from "../../img/kultura/teatrkukl/teatrkuklMain.webp";
import imgMain_7 from "../../img/kultura/kraevedcheskiy/kraevegcheskiyMain.webp";

//podium
import podium_1 from "../../img/kultura/podium/podiumSwiper1.webp";
import podium_2 from "../../img/kultura/podium/podiumSwiper2.webp";
import podium_3 from "../../img/kultura/podium/podiumSwiper3.webp";
import podium_4 from "../../img/kultura/podium/podiumSwiper4.webp";
import podium_5 from "../../img/kultura/podium/podiumSwiper5.webp";
//dramteatr
import dramteatr_1 from "../../img/kultura/dramteatr/dramteatrSwiper1.webp";
import dramteatr_2 from "../../img/kultura/dramteatr/dramteatrSwiper2.webp";
import dramteatr_3 from "../../img/kultura/dramteatr/dramteatrSwiper3.webp";
import dramteatr_4 from "../../img/kultura/dramteatr/dramteatrSwiper4.webp";
import dramteatr_5 from "../../img/kultura/dramteatr/dramteatrSwiper5.webp";
//gorizont
import gorizont_1 from "../../img/kultura/gorizont/gorizontSwiper1.webp"
import gorizont_2 from "../../img/kultura/gorizont/gorizontSwiper2.webp"
import gorizont_3 from "../../img/kultura/gorizont/gorizontSwiper3.webp"
import gorizont_4 from "../../img/kultura/gorizont/gorizontSwiper4.webp"
import gorizont_5 from "../../img/kultura/gorizont/gorizontSwiper5.webp"
//slavskogo
import slavskogo_1 from "../../img/kultura/slavskogo/slavskogoSwiper1.webp";
import slavskogo_2 from "../../img/kultura/slavskogo/slavskogoSwiper2.webp";
import slavskogo_3 from "../../img/kultura/slavskogo/slavskogoSwiper3.webp";
import slavskogo_4 from "../../img/kultura/slavskogo/slavskogoSwiper4.webp";
import slavskogo_5 from "../../img/kultura/slavskogo/slavskogoSwiper5.webp";
//centerIskustv
import centerIskustv_1 from "../../img/kultura/iskustvo/iskustvoSwiper1.webp";
import centerIskustv_2 from "../../img/kultura/iskustvo/iskustvoSwiper2.webp";
import centerIskustv_3 from "../../img/kultura/iskustvo/iskustvoSwiper3.webp";
import centerIskustv_4 from "../../img/kultura/iskustvo/iskustvoSwiper4.webp";
import centerIskustv_5 from "../../img/kultura/iskustvo/iskustvoSwiper5.webp";
//teatrKukl
import teatrKukl_1 from "../../img/kultura/teatrkukl/teatrkuklSwiper1.webp";
import teatrKukl_2 from "../../img/kultura/teatrkukl/teatrkuklSwiper2.webp";
import teatrKukl_3 from "../../img/kultura/teatrkukl/teatrkuklSwiper3.webp";
import teatrKukl_4 from "../../img/kultura/teatrkukl/teatrkuklSwiper4.webp";
import teatrKukl_5 from "../../img/kultura/teatrkukl/teatrkuklSwiper5.webp";
//kraeved
import kraeved_1 from "../../img/kultura/kraevedcheskiy/kraevegcheskiySwiper1.webp";
import kraeved_2 from "../../img/kultura/kraevedcheskiy/kraevegcheskiySwiper2.webp";
import kraeved_3 from "../../img/kultura/kraevedcheskiy/kraevegcheskiySwiper3.webp";
import kraeved_4 from "../../img/kultura/kraevedcheskiy/kraevegcheskiySwiper4.webp";
import kraeved_5 from "../../img/kultura/kraevedcheskiy/kraevegcheskiySwiper5.webp";


export const kulturaPage = [
  {
    id: "1",
    //заставка объявления
    img: imgMain_1,
    //название объявления
    name: "Театр-студия «ПОДИУМ»",
    vkLink: "https://vk.com/podium73",
    webLink: "https://www.teatr-podium73.ru/index.php?id=3",
    link: "/kulturaPage_1",
    // Главный текст на subPage странице
    title: "Театр-студия «ПОДИУМ» открыл свои двери для зрителей в 1956 году.",
    // последующий текст на subPage странице
    subtitle:
      "Последние 35 лет театром руководит режиссёр В. Казанджан. «Подиум» - театр любительский, однако уже 14 лет подряд он принимает участие и в межрегиональных и всероссийских фестивалях!",
    //место проведения
    place: "СОЦГОРОД",
    // ссылка
    placeLink: "https://yandex.ru/maps/-/CDbIjROS",
    //адрес
    adress: "просп. Димитрова, 31А,",
    //цена входа
    price: "-",
    //дата публикации
    data: "4 апреля 2024",
    img_1: podium_1,
    img_2: podium_2,
    img_3: podium_3,
    img_4: podium_4,
    img_5: podium_5,
  },
  {
    id: "2",
    //заставка объявления
    img: imgMain_2,
    //название объявления
    name: "Драм. театр имени А.Н. Островского",
    vkLink: "https://vk.com/dimteatr",
    webLink: "http://dimdramteatr.ru/",
    // Главный текст на subPage странице
    title:
      "Появление театра в Мелекессе (ныне Димитровград) было инициировано строительством Народного дома с театром в 1908 году по инициативе посадской Думы под руководством К. Г. Маркова. В театре арендовали сцену и зрительный зал для постановок спектаклей режиссёрами-любителями, так как профессиональных трупп не существовало. В репертуаре преобладали пьесы В. Шекспира и А. Н. Островского.",
    // последующий текст на subPage странице
    subtitle:
      "Димитровградский драмматический театр был основан в 1943 году. За это время в театре были поставлены почти все произведени А.Н. Островского, ставились разные пьесы А.П. Чехова, М. Горькова и других классиков. С января 2020 года художественным руководителем театра является Илья Сергеевич Костинкин.",
    //место проведения
    place: "Димитровградский драматический театр",
    // ссылка
    placeLink: "https://yandex.ru/maps/-/CDbIv6pi",
    //адрес
    adress: "ул. III Интернационала, д.74",
    //вид представления
    performance: "",
    //возростные ограничения
    age: "+6",
    //цена входа
    price: "от 200 до 2 000 руб.",
    //дата публикации
    data: "27 мая 2024",
    img_1: dramteatr_1,
    img_2: dramteatr_2,
    img_3: dramteatr_3,
    img_4: dramteatr_4,
    img_5: dramteatr_5,
  },
  {
    id: "3",
    //заставка объявления
    img: imgMain_3,
    //название объявления
    name: "Креативное бизнес-пространство «Горизонт»",
    webLink: "https://ulkul.ru/",
    vkLink: "https://vk.com/gorizont73",
    link: "/zdoroviePage_3",
    // Главный текст на subPage странице
    title:
      "Это кластер, объединяющий на своей территории творческих предпринимателей и институты развития города Димитровград. Здесь проходят бизнес-встречи, выставки, ярмарки и многое другое!",
    // последующий текст на subPage странице
    subtitle:
      "Раскрутим твой креативный проект, реализуем твою крутую идею.\nЛучшая площадка для твоего стартапа.\nВыставки, бизнес-встречи, концерты, МК, фотосъемки, лектории, бранчи и т.д.\nКонференц-зал\n\nКреативное бизнес-пространство «Горизонт» сдаёт в почасовую аренду:\n- Конференц-зал.\n- Переговорная.\n\nМы находимся по адресу:\nДимитрова, 8А (Креативное бизнес-пространство «Горизонт»)",
    //место проведения
    place: "СОЦГОРОД",
    // ссылка
    placeLink: "https://yandex.ru/maps/-/CDbMEWiA",
    //адрес
    adress: "просп. Димитрова, 8А",
    //вид представления
    performance: "-",
    //возростные ограничения
    age: "",
    //цена входа
    price: "-",
    //дата публикации
    data: "4 апреля 2024",
    img_1: gorizont_1,
    img_2: gorizont_2,
    img_3: gorizont_3,
    img_4: gorizont_4,
    img_5: gorizont_5,
  },
  {
    id: "4",
    //заставка объявления
    img: imgMain_4,
    //название объявления
    name: "НКЦ им. Е.П. Славского",
    vkLink: "https://vk.com/nkc_dimitrovgrad",
    webLink: "https://niiar.ru/",
    link: "/zdoroviePage_4",
    // Главный текст на subPage странице
    title:
      "Научно-культурный центр имени Е.П. Славского, всегда рад видеть вас! В центре проводятся различные мероприятия для жителей димитровграда",
    // последующий текст на subPage странице
    subtitle:
      "Научно-культурный центр имени Славского в Димитровграде открылся 6 ноября 1987 года как научно-политический центр НИИАР Октябрь. Автор проекта здания - ленинградский архитектор Ю.В. Вуйма, авторы 8 мозаичных полотен парадного дворика - ленинградские художники Василий Петрович Гусаров и Валентин Григорьевич Леканов. Сейчас НКЦ является одним из лучших центров Ульяновской области, где проводятся мероприятия не только местного, но и государственного масштаба.",
    //место проведения
    place: "СОЦГОРОД",
    // ссылка
    placeLink: "https://yandex.ru/maps/-/CDbMQEIm",
    //адрес
    adress: "просп. Димитрова, 12",
    //вид представления
    performance: "",
    //дата публикации
    data: "4 апреля 2024",
    img_1: slavskogo_1,
    img_2: slavskogo_2,
    img_3: slavskogo_3,
    img_4: slavskogo_4,
    img_5: slavskogo_5,
  },
  {
    id: "5",
    //заставка объявления
    img: imgMain_5,
    //название объявления
    name: "Центр современного искусства и дизайна",
    vkLink: "https://vk.com/centre_of_contemporary_art_dd",
    // Главный текст на subPage странице
    title:
      "Деятельность Центра направлена на развитие современного отечественного искусства в контексте мирового художественного процесса, формирование и реализацию программ и проектов в области современного искусства, архитектуры и дизайна в стране и за рубежом.",
    // последующий текст на subPage странице
    subtitle:
      "В настоящее время деятельность ГЦСИ сконцентрирована на четырех основных направлениях деятельности, каждая из которых реализуется рядом специальных программ: \n- кураторское (проведение выставок, форумов и фестивалей отечественного и зарубежного современного визуального искусства, реализация проектов в области видеоарта, перформанса и других направлений актуального искусства) ;\n- образовательное (проведение лекций, мастер-классов, семинаров и круглых столов по проблемам современного искусства; реализация образовательных программ в области теории и истории новейших практик современного искусства, а также специальных программ, ориентированных на детскую аудиторию) ;\n- информационное (создание информационной базы данных по современному искусству, формирование фондов медиатеки: CD, DVD, книги, журналы, каталоги, слайды, видео, фото и т. д.) ;\n- музейное (формирование постоянных коллекций, проведение экспертизы и реставрационных работ).",
    //место проведения
    place: "Академгородок",
    //адрес
    adress: "пр. Ленина, 2",
    // ссылка
    placeLink: "https://yandex.ru/maps/-/CDbMaUPv",
    //дата публикации
    data: "27 января 2024",
    img_1: centerIskustv_1,
    img_2: centerIskustv_2,
    img_3: centerIskustv_3,
    img_4: centerIskustv_4,
    img_5: centerIskustv_5,
  },
  {
    id: "6",
    //заставка объявления
    img: imgMain_6,
    //название объявления
    name: "Театр Кукол",
    vkLink: "https://vk.com/dtkfilial",
    webLink: "http://teatrkukol-73.ru/",
    link: "/zdoroviePage_6",
    // Главный текст на subPage странице
    title:
      "Театр кукол — это волшебное место, где оживают самые невероятные сказки и истории. В уютной атмосфере театра зрители погружаются в мир, населённый удивительными персонажами: мудрыми стариками, отважными героями, смешными зверятами и волшебными существами.",
    // последующий текст на subPage странице
    subtitle:
      "Каждый спектакль — это захватывающее путешествие, наполненное смехом, радостью и неожиданными поворотами. Здесь талантливые кукольники с помощью мастерски выполненных кукол и увлекательных сюжетов создают незабываемые представления, которые поражают воображение и взрослых, и детей. Театр кукол — это место, где сказка становится реальностью, а мечты воплощаются в жизнь.",
    //место проведения
    place: "СОЦГОРОД",
    // ссылка
    placeLink: "https://yandex.ru/maps/-/CDbMiY8F",
    //адрес
    adress: "ул. Королёва, 1",
    //вид представления
    performance: "",
    //возростные ограничения
    age: "-",
    //цена входа
    //дата публикации
    data: "4 апреля 2024",
    img_1: teatrKukl_1,
    img_2: teatrKukl_2,
    img_3: teatrKukl_3,
    img_4: teatrKukl_4,
    img_5: teatrKukl_5,
  },
  {
    id: "7",
    //заставка объявления
    img: imgMain_7,
    //название объявления
    name: "Краеведческий музей",
    webLink: "https://museumdd.ru/",
    vkLink: "https://vk.com/public62530139",
    // Главный текст на subPage странице
    title:
      "Здание, в котором располагается ныне музей, построил в 1904 г. мелекесский благотворитель и меценат Фёдор Григорьевич Марков для детского приюта. Архитектура его решена в русском теремковом стиле.",
    // последующий текст на subPage странице
    subtitle:
      "В годы советской власти в нём был организован детский дом. С 1942 г. по 1945 г. здание входило в систему помещений, занятых эвакогоспиталем № 1701, а после расформирования госпиталя в здании размещался стационар детской больницы. В 1974 г. вынесено решение Димитровградского горисполкома о передаче здания краеведческому музею, разместившегося в нём в 1978 г.",
    //место проведения
    place: "Краеведческий музей",
    // ссылка
    placeLink: "https://yandex.ru/maps/-/CDRSRD0c",
    //адрес
    adress: "ул. Прониной, 21, Димитровград",
    //вид представления
    performance: "Музей",
    //возростные ограничения
    age: "+6",
    //цена входа
    price: "от 250 руб.",
    //дата публикации
    data: "4 апреля 2024",
    img_1: kraeved_1,
    img_2: kraeved_2,
    img_3: kraeved_3,
    img_4: kraeved_4,
    img_5: kraeved_5,
  },
];
export default kulturaPage;
