

//images main photo
import imgMain_1 from "../../img/zdorovie/fok/zdorovieMain.webp";
import imgMain_2 from "../../img/zdorovie/bkAtom/bkAtomMain2.webp";
import imgMain_3 from "../../img/zdorovie/fkAtom/fkAtomMain.webp";
import imgMain_4 from "../../img/zdorovie/hammam/hammamMain.webp";
import imgMain_5 from "../../img/zdorovie/fakel/fakelMain.webp";
import imgMain_6 from "../../img/zdorovie/chempion/chempionMain.webp";
//fok
import fok_1 from "../../img/zdorovie/fok/zdorovieSwiper.webp";
import fok_2 from "../../img/zdorovie/fok/zdorovieSwiper2.webp";
import fok_3 from "../../img/zdorovie/fok/zdorovieSwiper3.webp";
import fok_4 from "../../img/zdorovie/fok/zdorovieSwiper4.webp";
import fok_5 from "../../img/zdorovie/fok/zdorovieSwiper5.webp";
//bkAtom
import bkAtom_1 from "../../img/zdorovie/bkAtom/bkAtomSwiper1.webp";
import bkAtom_2 from "../../img/zdorovie/bkAtom/bkAtomSwiper2.webp";
import bkAtom_3 from "../../img/zdorovie/bkAtom/bkAtomSwiper3.webp";
import bkAtom_4 from "../../img/zdorovie/bkAtom/bkAtomSwiper4.webp";
import bkAtom_5 from "../../img/zdorovie/bkAtom/bkAtomSwiper5.webp";
//fkAtom
import fkAtom_1 from "../../img/zdorovie/fkAtom/fkAtomSwiper1.webp";
import fkAtom_2 from "../../img/zdorovie/fkAtom/fkAtomSwiper2.webp";
import fkAtom_3 from "../../img/zdorovie/fkAtom/fkAtomSwiper3.webp";
import fkAtom_4 from "../../img/zdorovie/fkAtom/fkAtomSwiper4.webp";
import fkAtom_5 from "../../img/zdorovie/fkAtom/fkAtomSwiper5.webp";
//hammam
import hammam_1 from "../../img/zdorovie/hammam/hammamSwiper.webp";
import hammam_2 from "../../img/zdorovie/hammam/hammamSwiper2.webp";
import hammam_3 from "../../img/zdorovie/hammam/hammamSwiper3.webp";
import hammam_4 from "../../img/zdorovie/hammam/hammamSwiper4.webp";
import hammam_5 from "../../img/zdorovie/hammam/hammamSwiper5.webp";
//fakel
import fakel_1 from "../../img/zdorovie/fakel/fakelSwiper1.webp";
import fakel_2 from "../../img/zdorovie/fakel/fakelSwiper2.webp";
import fakel_3 from "../../img/zdorovie/fakel/fakelSwiper3.webp";
import fakel_4 from "../../img/zdorovie/fakel/fakelSwiper4.webp";
import fakel_5 from "../../img/zdorovie/fakel/fakelSwiper5.webp";
//champion
import champion_1 from "../../img/zdorovie/chempion/chempionSwiper1.webp";
import champion_2 from "../../img/zdorovie/chempion/chempionSwiper2.webp";
import champion_3 from "../../img/zdorovie/chempion/chempionSwiper3.webp";
import champion_4 from "../../img/zdorovie/chempion/chempionSwiper4.webp";
import champion_5 from "../../img/zdorovie/chempion/chempionSwiper5.webp";


export const zdoroviePage = [
  {
    id: "1",
    //заставка объявления
    img: imgMain_1,
    //название объявления
    name: "ФОК «Олимпийский»",
    vkLink: "https://vk.com/olimp173",
    link: "/zdoroviePage_1",
    // Главный текст на subPage странице
    title:
      " Фок «Олимпийский» – это активный отдых и удовольствие от занятий, широкий выбор программ для создания идеальной фигуры и укрепления организма.",
    // последующий текст на subPage странице
    subtitle:
      "Фок №1 «Олимпийский» – Наш фитнес-центр предлагает огромный выбор программ, индивидуальные и групповые тренировки, тренажерный зал, занятия по различным видам единоборств, поле с искусственным газоном многое другое.",
    //место проведения
    place: "ПОРТ",
    // ссылка
    placeLink: "https://yandex.ru/maps/-/CDVHVOiv",
    //адрес
    adress: "Дрогобычская улица, 30Г",
    //цена входа
    price: "-",
    //дата публикации
    data: "4 апреля 2024",
    img_1: fok_1,
    img_2: fok_2,
    img_3: fok_3,
    img_4: fok_4,
    img_5: fok_5,
  },
  {
    id: "2",
    //заставка объявления
    img: imgMain_2,
    //название объявления
    name: "Баскетбольный клуб «Атом»",
    vkLink: "https://vk.com/atomdgrad",
    link: "/zdoroviePage_2",
    // Главный текст на subPage странице
    title:
      "Автономная некоммерческая организация Баскетбольный клуб «Атом» г. Димитровград.",
    // последующий текст на subPage странице
    subtitle:
      "В «Атом» растят чемпионов не только в зале, но и в жизни:\n\n- Нравственное развитие/контроль успеваемости всех воспитанников.\n- Регулярные соревнования\n- Командные сборы на базе и за ее пределами.\n- Совместные досуговые мероприятия с командой\n- Индивидуальные занятия\n\n3 направления клуба:\nLite - группы общей физической подготовки\nSemi Pro - группы развития баскетбольных навыков\nPro - группы основных команд клуба\n\nВозрастные группы:\n- с 3 до 7 лет\n- с 7 до 9 лет\n- с 9 до 12 лет\n- с 12 до 16 лет",
    //место проведения
    place: "ДТК",
    // ссылка
    placeLink: "https://yandex.ru/maps/-/CDVHR-4Y",
    //адрес
    adress: "проспект Автостроителей, 63",
    //цена входа
    price: "от 2500 рублей",
    //дата публикации
    data: "4 апреля 2024",
    img_1: bkAtom_1,
    img_2: bkAtom_2,
    img_3: bkAtom_3,
    img_4: bkAtom_4,
    img_5: bkAtom_5,
  },
  {
    id: "3",
    //заставка объявления
    img: imgMain_3,
    //название объявления
    name: "Академия футбола «Атом»",
    webLink: "https://vk.link/academy_football_atom",
    vkLink: "https://vk.com/academy_football_atom",
    link: "/zdoroviePage_3",
    // Главный текст на subPage странице
    title:
      "Добро пожаловать в Академию футбола «Атом»! Мы предлагаем уникальные возможности для юных футболистов развивать свои навыки под руководством опытных тренеров.",
    // последующий текст на subPage странице
    subtitle:
      "Наши программы включают современные тренировочные методики, индивидуальный подход и участие в соревнованиях различного уровня. В «Атоме» каждый ребенок сможет раскрыть свой спортивный потенциал и достичь новых высот в футболе!\n\nАкадемия футбола «Атом» г. Димитровград официально открыта 01 февраля 2022 года.\n\nВ настоящее время в Академии тренируются только димитровградские футболисты 2017 - 2007 годов рождения.\n\nВ структуре есть группы набора для малышей 2018-2019 годов рождения.\n\nВ июне 2022 года команда 2009 года рождения дебютировала в Первенстве России зоны Приволжье и заняла 4-е место из 12 команд!",
    //место проведения
    place: "ПОРТ",
    // ссылка
    placeLink: "https://yandex.ru/maps/-/CDVLQCls",
    //адрес
    adress: "Дрогобычская ул., 30",
    //вид представления
    performance: "-",
    //возростные ограничения
    age: "",
    //цена входа
    price: "-",
    //дата публикации
    data: "4 апреля 2024",
    img_1: fkAtom_1,
    img_2: fkAtom_2,
    img_3: fkAtom_3,
    img_4: fkAtom_4,
    img_5: fkAtom_5,
  },
  {
    id: "4",
    //заставка объявления
    img: imgMain_4,
    //название объявления
    name: "Оздоровительный комплекс Хаммам",
    vkLink: "https://vk.com/okhamman?from=search",
    link: "/zdoroviePage_4",
    // Главный текст на subPage странице
    title: "Оздоровительный комплекс Хаммам Димитровград",
    // последующий текст на subPage странице
    subtitle:
      "Оздоровительный комплекс Хаммам это всегда гостеприимный персонал, готовый обеспечить Вам незабываемый отдых в самой большой турецкой бане Ульяновской области.\n\nПлавательный бассейн \nПарная:финская, инфракрасная, турецкая",
    //место проведения
    place: "(ост. ДУС, за Типографией)",
    // ссылка
    placeLink: "https://yandex.ru/maps/-/CDVL4Azm",
    //адрес
    adress: "Севастопольская ул., 5В",
    //вид представления
    performance: "",
    //возростные ограничения
    age: "-",
    //цена входа
    price: "от 800 рублей",
    //дата публикации
    data: "4 апреля 2024",
    img_1: hammam_1,
    img_2: hammam_2,
    img_3: hammam_3,
    img_4: hammam_4,
    img_5: hammam_5,
  },
  {
    id: "5",
    //заставка объявления
    img: imgMain_5,
    //название объявления
    name: "ФОК «Факел»",
    vkLink: "https://vk.com/fokfakel73",
    link: "/zdoroviePage_5",
    // Главный текст на subPage странице
    title:
      "Спорт среди всех возрастных и социальных групп населения - от профессиональных спортсменов до любителей!",
    // последующий текст на subPage странице
    subtitle:
      "Физкультурно-оздоровительный комплекс «Факел» открылся в 2017 году.\n\nРежим работы: ежедневно с 9.00 - 21.00.\nТелефон администратора: 8 (84232) 2-20-02.\n\nДиректор Фриауф Владимир Владимирович.\nE-mail: fokfakel@yandex.ru\n\nАдрес: 433560, Ульяновская область, Новомалыклинский район, село Новая Малыкла, улица Коммунальная, дом 2а.\n\nЗдание ФОК предназначено для спортивных тренировочных занятий-секций:\n- игровые виды спорта (универсальный спортивный зал 33 х 18 м),\n- единоборства (универсальный спортивный зал 15 х 12 м),\n- плавание (бассейн 25 х 10 м 4 дорожки),\n- тренажерный зал.\n\nКроме того, ФОК «Факел» имеет:\n- сауна с малым бассейном на 8 чел.,\n- малый конференц-зал на 20 чел.,\n- большой конференц-зал на 50 чел.\n\nФОК оборудован раздевалками, душевыми комнатами, установлены пандусы и туалеты для маломобильных групп.",
    //место проведения
    place: "с. Новая Малыкла",
    // ссылка
    placeLink: "https://yandex.ru/maps/-/CDVLeE1v",
    //адрес
    adress: "Коммунальная ул., 2А, село Новая Малыкла",
    //вид представления
    performance: "Танцевальное сообщество",
    //возростные ограничения
    age: "-",
    //цена входа
    price: "от 100 руб.",
    //дата публикации
    data: "4 апреля 2024",
    img_1: fakel_1,
    img_2: fakel_2,
    img_3: fakel_3,
    img_4: fakel_4,
    img_5: fakel_5,
  },
  {
    id: "6",
    //заставка объявления
    img: imgMain_6,
    //название объявления
    name: "Спортклуб Чемпион",
    vkLink: "https://vk.com/sportclub_champion",
    link: "/zdoroviePage_6",
    // Главный текст на subPage странице
    title:
      "Спортклуб Чемпион — это добровольное объединение юных спортсменов и любителей спорта, объединенных общими интересами, целями и деятельностью.",
    // последующий текст на subPage странице
    subtitle:
      "Спортклуб предоставляет различные физкультурно-спортивные услуги и является, наряду со спортивными школами разного уровня, организациями, в рамках которых осуществляется основная тренировочная деятельность.",
    //место проведения
    place: "ТК Арбат",
    // ссылка
    placeLink: "https://yandex.ru/maps/-/CDVLr-NH",
    //адрес
    adress: "просп. Ленина, 23А, ",
    //вид представления
    performance: "",
    //возростные ограничения
    age: "-",
    //цена входа
    price: "от 500 руб.",
    //дата публикации
    data: "4 апреля 2024",
    img_1: champion_1,
    img_2: champion_2,
    img_3: champion_3,
    img_4: champion_4,
    img_5: champion_5,
  },
];
export default zdoroviePage;
